import React from 'react'
import styled from 'styled-components'
import { Trade, TradeType } from '@pancakeswap/sdk'
import { Card, CardBody, Text } from 'poodlefi-libs-uikit'
import useTheme from 'hooks/useTheme'
import { useTranslation } from 'react-i18next'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'

const CustomCard = styled(Card)`
  background-color: ${({ theme }) => (theme.isDark ? '#191034' : '#fff')};
`

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { isDark } = useTheme()
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { t } = useTranslation()

  return (
    <CustomCard style={{ borderRadius: '18px' }}>
      <CardBody>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px" color={isDark ? '#fff' : '#1C1C28'}>
              {isExactIn ? t('Minimum received') : t('Maximum sold')}
            </Text>
            <QuestionHelper
              text={t('Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.')}
            />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px" color={isDark ? '#fff' : '#1C1C28'}>
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ?? '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ?? '-'}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px" color={isDark ? '#fff' : '#1C1C28'}>
              {t('Price Impact')}
            </Text>
            <QuestionHelper text={t('The difference between the market price and estimated price due to trade size.')} />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <Text fontSize="14px" color={isDark ? '#fff' : '#1C1C28'}>
              {t('Liquidity Provider Fee')}
            </Text>
            <QuestionHelper
              text={t(
                'For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers and 0.03% goes to the PancakeSwap treasury.'
              )}
            />
          </RowFixed>
          <Text fontSize="14px" color={isDark ? '#fff' : '#1C1C28'}>
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        </RowBetween>
      </CardBody>
    </CustomCard>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const { isDark } = useTheme()
  const [allowedSlippage] = useUserSlippageTolerance()
  const { t } = useTranslation()
  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <SectionBreak />
              <AutoColumn style={{ padding: '0 24px' }}>
                <RowFixed>
                  <Text fontSize="14px" color={isDark ? '#fff' : '#1C1C28'}>
                    {t('Route')}
                  </Text>
                  <QuestionHelper text={t('Routing through these tokens resulted in the best price for your trade.')} />
                </RowFixed>
                <SwapRoute trade={trade} isDark={isDark} />
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
