/* eslint-disable consistent-return */
import fetchWrapper from 'helpers/fetch-wrapper'

const apiBaseUrl = `/referral`

const getTree = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/get-tree/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe(data?.data)
  })
}

const getNumberOfChild = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/get-number-of-child/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe(data?.data)
  })
}

export default { getTree, getNumberOfChild }
