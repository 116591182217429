/* eslint-disable consistent-return */
import { stringify } from 'helpers'
import fetchWrapper from 'helpers/fetch-wrapper'

const apiBaseUrl = `/package`

const search = async (cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/search`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe()
  })
}

const create = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/create`, body).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const myPackage = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/wallet/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe()
  })
}

const claimInterest = async (package_id: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/claim-interest/${package_id}`).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe(data?.data)
  })
}

const searchAll = async (payload: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  const qStr = payload ? stringify(payload) : ''

  return fetchWrapper.get(`${apiBaseUrl}/search-all?${qStr}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

export default { search, create, myPackage, claimInterest, searchAll }
