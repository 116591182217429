/* eslint-disable consistent-return */
import { AuthCache } from 'constants/index'
import fetchWrapper from 'helpers/fetch-wrapper'
import Cookies from 'js-cookie'

const login = (token: string) => {
  Cookies.set(AuthCache.AUTH_TOKEN_CACHE, token)
}

const logout = (cbs?: () => void) => {
  Cookies.remove(AuthCache.AUTH_TOKEN_CACHE)
  if (cbs) cbs()
}

const verifySignature = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`/verify-message`, body).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const generateWallet = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`/generate-wallet`, body).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const testIp = async (cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`/test-ip`).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

export default { login, logout, verifySignature, generateWallet, testIp }
