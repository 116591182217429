import { ChainId, JSBI, Percent, Token, WETH } from '@pancakeswap/sdk'

export const ROUTER_ADDRESS = process.env.PAN_R || '0x595aE095ad384e0009CD8BF344e662Fe1e215fA6'

export const ADDRESS_ADMIN = process.env.REACT_APP_ADDRESS_ADMIN as string
export const STAKING_SMART_CONTRACT = process.env.REACT_APP_CONTRACT as string
export const STAKING_TOKEN_CONTRACT = process.env.REACT_APP_TOKEN_CONTRACT as string
export const TOKEN_CONTRACT = process.env.REACT_APP_TOKEN_CONTRACT as string
export const TOKEN_CONTRACT_USDT = process.env.REACT_APP_TOKEN_CONTRACT_USDT as string
export const ADDRESS_WITHDRAW = process.env.REACT_APP_ADDRESS_WITHDRAW as string

// Github
export const ENV_ORGANIZATION = process.env.REACT_APP_ORGANIZATION
export const ENV_REPOS = process.env.REACT_APP_REPOS
export const ENV_FILENAME = process.env.REACT_APP_FILENAME

export const DEFAULT_TOKEN_SELECT = 'BNB'
export const DEFAULT_TOKEN_SELECT_OUTPUT = STAKING_TOKEN_CONTRACT

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const BASE_TOKEN = new Token(ChainId.MAINNET, STAKING_TOKEN_CONTRACT, 6, 'PFI', 'PFI')

export const DAI = new Token(ChainId.MAINNET, '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7cea3dedca5984780bafc599bd69add087d56', 18, 'BUSD', 'Binance USD')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059ff775485246999027b3197955', 18, 'USDT', 'Tether USD')
export const UST = new Token(ChainId.MAINNET, '0x23396cf899ca06c4472205fc903bdb4de249d6fc', 18, 'UST', 'Wrapped UST Token')
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token'
)
export const SPW = new Token(ChainId.MAINNET, STAKING_SMART_CONTRACT, 18, 'SPW', 'SPW Token')

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.TESTNET]: [WETH[ChainId.TESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT, UST, ETH],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, USDT],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82', 18, 'CAKE', 'PancakeSwap Token'),
      new Token(ChainId.MAINNET, '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
    ],
    [BUSD, USDT],
    [DAI, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

export const MODAL_TYPES = {
  swap: 'SWAP',
  stake: 'STAKE',
  withdraw: 'WITHDRAW',
}

// API
export const API_BASE_URL = process.env.REACT_APP_API as string
export const StatusCode = {
  OK: 200,
  Created: 201,
  Unauthorized: 401,
  Forbidden: 403,
}
export const AuthCache = {
  AUTH_CACHE: 'web3ton',
  AUTH_TOKEN_CACHE: 'web3ton-token',
}
