/* eslint-disable react/react-in-jsx-scope */
import { ConnectorNames } from 'connectors/connectorsV2'
// import Metamask from './icons/Metamask'
// import TrustWallet from './icons/TrustWallet'
// import WalletConnect from './icons/WalletConnect'
// import BinanceChain from './icons/BinanceChain'
// import TokenPocket from './icons/TokenPocket'
import { Config } from './types'

const connectors: Config[] = [
  // {
  //   title: 'METAMASK',
  //   icon: Metamask,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'TRUST WALLET',
  //   icon: TrustWallet,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'TokenPocket',
  //   icon: TokenPocket,
  //   connectorId: ConnectorNames.Injected,
  // },
  // {
  //   title: 'CoinBase',
  //   icon: '/images/wallet/Coinbase.png',
  //   connectorId: ConnectorNames.WalletLink,
  // },
  // {
  //   title: 'BitKeep',
  //   icon: '/images/wallet/BitKeep.png',
  //   connectorId: ConnectorNames.Injected,
  // },
  {
    title: 'Metamask',
    icon: '/images/wallet/metamask.png',
    connectorId: ConnectorNames.Injected,
    href: 'https://metamask.app.link/dapp',
  },
  {
    title: 'WalletConnect',
    icon: '/images/wallet/wallet-connect.png',
    connectorId: ConnectorNames.WalletConnect,
  },
  {
    title: 'Trust Wallet',
    icon: '/images/wallet/trust-wallet.png',
    connectorId: ConnectorNames.Injected,
    href: 'https://link.trustwallet.com',
  },
  {
    title: 'Binance Chain Wallet',
    icon: '/images/wallet/binance.png',
    connectorId: ConnectorNames.BSC,
  },
  {
    title: 'SafePal Wallet',
    icon: '/images/wallet/safepal.png',
    connectorId: ConnectorNames.Injected,
    href: 'https://www.safepal.com/download',
  },
  {
    title: 'OKX Wallet',
    icon: '/images/wallet/okx.png',
    connectorId: ConnectorNames.Injected,
    href: 'https://www.okx.com/download',
  },
]

export default connectors
