import React, { useCallback, useEffect, useState } from 'react'
import { Button, InputNumber, Modal } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { accountService, packageService } from 'services'
import { AppState } from 'state'
import { formatNumber } from 'helpers'
import { CheckCircleOutlined } from '@ant-design/icons'
import useSignMessage from 'hooks/useSignMessage'
import { toLocaleString } from 'utils'
import useToast from 'hooks/useToast'
import { useWeb3React } from '@web3-react/core'
import { setBalance } from 'state/account/actions'

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  padding: 30px 0px 40px;
  min-height: 200px;

  @media (min-width: 576px) {
    padding: 0.5rem 14px 1rem;
  }

  .md-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 100%;

    button {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 250px;
    }

    .box-currency {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;

      p {
        font-weight: 600;
      }
    }
  }

  .package-content {
    position: relative;
    padding: 15px 10px;
    z-index: 1;

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 20px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      p {
        font-weight: bold;
      }
    }

    .box-commission-rule {
      flex-wrap: wrap;

      .box-rule {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-top: 10px;

        p {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #fff;
          padding: 10px;
          font-weight: normal;

          &:not(:last-child) {
            margin-right: 5px;
          }

          span {
            display: contents;
            color: #2ebd85;
            font-weight: bold;
          }
        }
      }
    }

    .box-action {
      button {
        width: 100%;
      }
    }

    .box-input {
      display: block;

      p {
        font-size: 13px;
        font-weight: 300;
        margin-bottom: 2px;
      }

      .ant-input-number {
        width: 100%;
      }
    }
  }
`

const ModalInvestNow = ({ modal, setModal }: { modal: any; setModal: (input: any) => void }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const signMessage = useSignMessage()
  const { toastError, toastWarning } = useToast()
  const { account } = useWeb3React()

  const walletAddressDepoist: any = useSelector<AppState>((state) => state.account.walletAddress)
  const balance: any = useSelector<AppState>((state) => state.account.balance)

  const [addressDepoistState, setAddressDepositState] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [view, setView] = useState<number>(0)
  const [paramInvest, setInvest] = useState<any>()

  useEffect(() => {
    if (walletAddressDepoist?.walletAddress) {
      setAddressDepositState(walletAddressDepoist?.walletAddress)
    } else {
      setAddressDepositState('')
    }
  }, [walletAddressDepoist?.walletAddress])

  useEffect(() => {
    if (walletAddressDepoist?.walletAddress) {
      setInvest((prev) => ({
        ...prev,
        amount: modal?.data?.min_amount / 10 ** 18,
        package_id: modal?.data?.id,
      }))
    }
  }, [modal?.data?.id, modal?.data?.min_amount, walletAddressDepoist?.walletAddress])

  const handleInvest = useCallback(async () => {
    if (paramInvest && account) {
      try {
        setLoading(true)
        await signMessage(setLoading)
      } catch {
        toastWarning('You have canceled the transaction.')
        setLoading(false)
        return
      }

      const _param = { ...paramInvest, amount_invest: toLocaleString(paramInvest?.amount * 10 ** 18) }

      setLoading(true)
      packageService.create(
        _param,
        () => {
          setLoading(false)
          setView(1)

          accountService.getBalance(
            account,
            (data) => {
              dispatch(
                setBalance({
                  balance: data,
                  isLoading: false,
                })
              )
            },
            () => {
              dispatch(
                setBalance({
                  balance: {
                    PBC: 0,
                    BNB: 0,
                    USDT: 0,
                  },
                  isLoading: false,
                })
              )
            }
          )
        },
        (e) => {
          toastError(e?.error || e?.message || 'Please try again later.')
          setLoading(false)
        }
      )
    }
  }, [account, dispatch, paramInvest, signMessage, toastError, toastWarning])

  return (
    <Modal
      key="modal-connect"
      visible={modal?.toggle}
      centered
      width={640}
      title={`${t('Invest Now')}`}
      footer={null}
      onCancel={() => {
        if (!loading) {
          setModal({
            toogle: false,
            data: null,
          })
          setView(0)
          setInvest(null)
        }
      }}
      closeIcon={<img src="/images/icons/close-white.png?v=1.1" alt="" />}
    >
      <ModalContent>
        <div className="md-content">
          {addressDepoistState ? (
            <>
              {view === 0 ? (
                <>
                  <div className="box-currency">
                    <div style={{ width: '100%' }}>
                      <p style={{ textAlign: 'center', fontSize: '20px' }}>Are you sure you want to invest?</p>

                      <div className="package-content">
                        <div>
                          <p>Name:</p>
                          <p>{modal?.data?.name}</p>
                        </div>
                        <div>
                          <p>Min amount:</p>
                          <p>
                            ${formatNumber(modal?.data?.min_amount / 10 ** 18, 0, 0)} {modal?.data?.currency}
                          </p>
                        </div>
                        <div>
                          <p>Max amount:</p>
                          <p>
                            ${formatNumber(modal?.data?.max_amount / 10 ** 18, 0, 0)} {modal?.data?.currency}
                          </p>
                        </div>
                        <div>
                          <p>Daily Interest Rate:</p>
                          <p style={{ color: '#2ebd85' }}>{modal?.data?.daily_interest_rate / 100}%</p>
                        </div>
                        <div>
                          <p>Payout currency:</p>
                          <p>{modal?.data?.payout_currency}</p>
                        </div>
                        <div>
                          <p>Commission currency:</p>
                          <p>{modal?.data?.commission_currency}</p>
                        </div>

                        <div className="box-input">
                          <p>
                            Balance: {formatNumber(balance?.balance[`${modal?.data?.currency}`] / 10 ** 18)}{' '}
                            {modal?.data?.currency}
                          </p>
                          <InputNumber
                            max={balance?.balance[`${modal?.data?.currency}`] / 10 ** 18}
                            min={modal?.data?.min_amount / 10 ** 18}
                            value={paramInvest?.amount}
                            onChange={(e) =>
                              setInvest((prev) => ({
                                ...prev,
                                amount: e,
                              }))
                            }
                            placeholder="Enter the amount you wish to invest."
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <Button type="primary" onClick={handleInvest} loading={loading}>
                    Confirm
                  </Button>
                </>
              ) : (
                <div className="box-currency">
                  <div style={{ textAlign: 'center' }}>
                    <CheckCircleOutlined style={{ fontSize: '60px', color: '#52C41A', marginBottom: '20px' }} />
                    <p style={{ fontSize: '24px' }}>You have successfully invested in {modal?.data?.name}.</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    setLoading(true)
                    await signMessage(setLoading)
                  } catch {
                    toastWarning('You have canceled the transaction.')
                    setLoading(false)
                  }
                }}
                loading={loading || walletAddressDepoist?.isLoading}
              >
                Get Address Deposit
              </Button>
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalInvestNow)
