/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { Button } from 'antd'
import { formatNumber } from 'utils/formatInfoNumbers'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { transactionService } from 'services'
import { useWeb3React } from '@web3-react/core'

const WrapPackage = styled.div`
  .package-items {
    position: relative;
    box-shadow: rgb(30, 150, 200) 0px 0px 16.7px 0px;
    border-radius: 1rem;
    border: 4px solid #0063f7;

    &.bronze {
      box-shadow: rgb(199, 124, 48) 0px 0px 2px 0px;
      border: 1px solid #c77c30;
    }

    &.silver {
      box-shadow: rgb(186, 186, 186) 0px 0px 6px 0px;
      border: 2px solid #bababa;
    }

    &.gold {
      box-shadow: rgb(246, 185, 1) 0px 0px 6px 0px;
      border: 3px solid #f6ba01;
    }

    &.platinum {
      box-shadow: rgb(222, 221, 219) 0px 0px 16.7px 0px;
      border: 3px solid #dedddb;
    }

    &.diamond {
      box-shadow: rgb(58, 219, 240) 0px 0px 16.7px 0px;
      border: 3px solid #3adaf0;
    }

    .package-bg-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('/images/packages/bg-item.png');
      background-size: 100% 100%;
      animation: animationBackground 80s infinite linear;
      z-index: 0;
      opacity: 0.5;
      border-radius: 0.5rem;
    }

    .package-content {
      position: relative;
      padding: 15px 10px;
      z-index: 1;

      ${({ theme }) => theme.mediaQueries.lg} {
        padding: 20px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 26px;
        }

        p {
          font-weight: bold;
        }
      }

      .box-commission-rule {
        flex-wrap: wrap;

        .box-rule {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin-top: 10px;

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            padding: 10px;
            font-weight: normal;

            &:not(:last-child) {
              margin-right: 5px;
            }

            span {
              display: contents;
              color: #2ebd85;
              font-weight: bold;
            }
          }
        }
      }

      .box-action {
        gap: 10px;
        flex-direction: column;

        ${({ theme }) => theme.mediaQueries.lg} {
          flex-direction: row;
        }

        button {
          width: 100%;
        }
      }
    }
  }
`

const TIME_CLAIM = 9 // Time UTC

const Package = ({
  dataItem,
  HandleClaimInterest,
  setModalHistory,
}: {
  dataItem: any
  HandleClaimInterest: (package_id: string) => void
  setModalHistory: (input: { toggle: boolean; data: any }) => void
}) => {
  const { isDark } = useTheme()
  const { account } = useWeb3React()

  const [cumulativeTotalReward, setCumulativeTotalReward] = useState<number>(0)
  const [hasClaimedToday, setHasClaimedToday] = useState(false)
  const [principalAmount, setPrincipalAmount] = useState(0)
  const [exchangeRatePBC, setExchangeRatePBC] = useState(0)
  const [totalCommissionUsdt, setTotalCommissionUsdt] = useState(0)

  useEffect(() => {
    const checkClaimed = () => {
      const today = moment().format('YYYY-MM-DD')
      const claimedToday = dataItem?.interest_claim_history?.some((history) => {
        const claimedDate = moment.unix(history.claimed_at).format('YYYY-MM-DD')
        return claimedDate === today
      })

      setHasClaimedToday(claimedToday)
    }

    checkClaimed()
    const interval = setInterval(() => {
      checkClaimed()
    }, 1000)

    return () => clearInterval(interval)
  }, [dataItem])

  useEffect(() => {
    const getTotal = () => {
      if (dataItem && dataItem.package && dataItem.start_date) {
        const today = moment().utc().startOf('day')
        const claimedToday = dataItem?.interest_claim_history?.some((history) => {
          const claimedDate = moment.unix(history.claimed_at).utc().startOf('day')
          return claimedDate.isSame(today, 'day')
        })

        const startTimestamp = +dataItem.start_date
        const yesterdayClaim = moment().utc().startOf('day').subtract(1, 'day').add(TIME_CLAIM, 'hours')
        const todayClaim = moment().utc().startOf('day').add(TIME_CLAIM, 'hours')
        let startDate: any = null
        let endDate = moment().utc().unix()

        if (claimedToday) {
          const startOfClaimPeriod = moment().utc().startOf('day').add(TIME_CLAIM, 'hours')
          if (moment().utc().isAfter(startOfClaimPeriod)) {
            startDate = startOfClaimPeriod.unix()
          }
        } else {
          if (moment.unix(startTimestamp).utc().isAfter(yesterdayClaim)) {
            startDate = startTimestamp
          } else {
            startDate = yesterdayClaim.unix()
          }

          if (moment().utc().isAfter(todayClaim)) {
            endDate = todayClaim.unix()
          }
        }

        // Tính toán lãi suất
        if (startDate !== null) {
          const amount = +dataItem.amount
          let elapsedTimeInSeconds = endDate - startDate

          if (elapsedTimeInSeconds < 0) {
            elapsedTimeInSeconds = moment().utc().unix() - startDate
          }

          const amountRewardPerSecond = amount * (+dataItem.package.daily_interest_rate / 10000 / 86400)
          const totalReward = amountRewardPerSecond * elapsedTimeInSeconds
          setCumulativeTotalReward(totalReward)
        }
      }
    }

    getTotal()
    const interval = setInterval(() => {
      getTotal()
    }, 1000)

    return () => clearInterval(interval)
  }, [dataItem])

  const calculatePrincipal = (amount, dailyInterestRate, days) => {
    // Chuyển lãi suất từ phần trăm sang số thập phân
    const dailyRateDecimal = dailyInterestRate / 10000
    // Tính số tiền ban đầu
    const principal = amount / (1 + dailyRateDecimal) ** (days || 0)
    return principal
  }

  useEffect(() => {
    setPrincipalAmount(
      calculatePrincipal(dataItem?.amount, dataItem?.package?.daily_interest_rate, dataItem?.interest_claim_history?.length)
    )
    setExchangeRatePBC(
      +formatNumber(
        dataItem?.amount_invest /
          calculatePrincipal(dataItem?.amount, dataItem?.package?.daily_interest_rate, dataItem?.interest_claim_history?.length),
        4,
        4
      )
    )
  }, [
    dataItem?.amount,
    dataItem?.amount_invest,
    dataItem?.interest_claim_history?.length,
    dataItem?.package?.daily_interest_rate,
  ])

  useEffect(() => {
    if (account) {
      transactionService.getCommissionUsdtTransactions(account, (data) => {
        setTotalCommissionUsdt(data?.generalCommission / 10 ** 18)
      })
    }
  }, [account])

  return (
    <WrapPackage className={isDark ? 'theme-dark' : ''}>
      <div className={`package-items ${dataItem?.package?.name?.toLowerCase()}`}>
        <div className="package-bg-item" />

        <div className="package-content">
          <div>
            <p>Name:</p>
            <p>{dataItem?.package?.name}</p>
          </div>
          <div>
            <p>Amount:</p>
            <p>
              ({formatNumber(dataItem?.amount_invest / 10 ** 18, 2, 2)}$) {formatNumber(principalAmount / 10 ** 18, 2, 2)}
            </p>
          </div>
          <div>
            <p>Maxout:</p>
            <p>
              {formatNumber(
                (dataItem?.interest_claim_history?.reduce((sum, item) => {
                  return sum + +item.claimed_amount / 10 ** 18
                }, 0) || 0) *
                  exchangeRatePBC +
                  totalCommissionUsdt,
                2,
                2
              )}
              $ / {formatNumber((dataItem?.amount_invest * (dataItem?.package?.max_out / 10000)) / 10 ** 18, 2, 2)}$
            </p>
          </div>
          <div>
            <p>Daily Interest Rate:</p>
            <p style={{ color: '#2ebd85' }}>{formatNumber(dataItem?.package?.daily_interest_rate / 100, 1, 1)}%</p>
          </div>
          <div>
            <p>Cumulative Total Reward:</p>
            <p>
              {formatNumber(cumulativeTotalReward / 10 ** 18)} {dataItem?.package?.payout_currency}
            </p>
          </div>
          <div>
            <p>History compound:</p>
            <p>
              <button
                type="button"
                style={{ color: '#2ebd85', textDecorationLine: 'underline' }}
                onClick={() =>
                  setModalHistory({
                    toggle: true,
                    data: dataItem,
                  })
                }
              >
                View
              </button>
            </p>
          </div>

          <div className="box-action">
            <Button type="primary" onClick={() => HandleClaimInterest(dataItem.id)} disabled={hasClaimedToday}>
              Compound
            </Button>
          </div>
        </div>
      </div>
    </WrapPackage>
  )
}

export default Package
