/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useCallback, useEffect, useState } from 'react'
import { UsergroupAddOutlined, SearchOutlined, ExportOutlined } from '@ant-design/icons'
import { Flex } from 'poodlefi-libs-uikit'
import { Button, Input, Table, Tooltip } from 'antd'
import { shortenString } from 'utils'
import moment from 'moment'
import { accountService } from 'services'
import debounce from 'lodash.debounce'
import { formatAmount, formatNumber } from 'utils/formatInfoNumbers'
import { CSVLink } from 'react-csv'

const WrapUser = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem;
    border-radius: 1.5rem;

    .header-transaction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 16px;
      margin-bottom: 20px;

      .ant-input-group-wrapper {
        max-width: 300px;
      }
    }

    .wrap-button-export {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 12px;

      &.loading {
        pointer-events: none;
      }
    }
  }
`

const columns = [
  {
    title: 'Address',
    dataIndex: 'account_address',
    key: 'account_address',
    render: (address) => (
      <a href={`https://bscscan.com/address/${address}`} target="_Blank" rel="noreferrer">
        {shortenString(address)}
      </a>
    ),
  },
  {
    title: 'Address System',
    dataIndex: 'account',
    key: 'account',
    render: (_, record) => (
      <a href={`https://bscscan.com/address/${record?.account?.account_address}`} target="_Blank" rel="noreferrer">
        {shortenString(record?.account?.account_address)}
      </a>
    ),
  },
  {
    title: 'USDT',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, recod) => <p>{formatAmount(recod?.balance?.USDT / 10 ** 18)}</p>,
  },
  {
    title: 'BNB',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, recod) => <p>{formatNumber(recod?.balance?.BNB / 10 ** 18, 4, 4)}</p>,
  },
  {
    title: 'PBC',
    dataIndex: 'balance',
    key: 'balance',
    render: (_, recod) => <p>{formatAmount(recod?.balance?.PBC / 10 ** 18)}</p>,
  },
  {
    title: 'Status withdraw',
    dataIndex: 'no_withdraw',
    key: 'no_withdraw',
    render: (status) => <p>{status === 1 ? 'Inactive' : 'Active'}</p>,
  },
  {
    title: 'Time',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (timeStamp) => (
      <p>
        <Tooltip placement="top" title={moment(timeStamp).format('YYYY-MM-DD HH:mm:ss')}>
          {moment(timeStamp).fromNow()}
        </Tooltip>
      </p>
    ),
  },
]

const User = () => {
  const { isDark } = useTheme()

  const [paramSearch, setParamSearch] = useState<any>({
    account_address: '',
    per_page: 50,
    page: 1,
  })
  const [user, setuser] = useState({
    isLoading: true,
    length: 0,
    docs: [],
    current_page: 0,
    total: 0,
    per_page: 0,
  })
  const [userAll, setUserAll] = useState({
    isLoading: true,
    docs: [],
  })
  const [inputSearch, setInputSearch] = useState<string>('')
  const [firstFetch, setFirstFetch] = useState<boolean>(true)

  useEffect(() => {
    if (!firstFetch) {
      accountService.searchPagination(
        {
          isGetAll: true,
        },
        (data) => {
          window.scrollTo(0, 0)
          setUserAll({
            ...data?.data,
            isLoading: false,
          })
        }
      )
    }
  }, [firstFetch])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      setParamSearch((prev) => ({
        ...prev,
        account_address: value,
      }))
    }, 500),
    []
  )

  const handleInputChange = (e) => {
    setInputSearch(e.target.value)
    debouncedSearch(e.target.value)
  }

  useEffect(() => {
    setuser((prev) => ({
      ...prev,
      isLoading: true,
    }))

    accountService.searchPagination(paramSearch, (data) => {
      window.scrollTo(0, 0)
      setuser({
        ...data?.data,
        isLoading: false,
      })
      setFirstFetch(false)
    })
  }, [paramSearch])

  return (
    <WrapUser className={isDark ? 'theme-dark' : ''}>
      <div className="container">
        <div className="inner-content">
          <div className="header-transaction">
            <Flex alignItems="center">
              <UsergroupAddOutlined style={{ marginRight: '8px', fontSize: '22px' }} /> User
            </Flex>

            <Input
              addonBefore={<SearchOutlined style={{ color: '#000' }} />}
              placeholder="Address"
              onChange={handleInputChange}
              value={inputSearch}
            />
          </div>

          <div className={`wrap-button-export ${userAll?.isLoading ? 'loading' : ''}`}>
            <CSVLink
              data={userAll?.docs?.map((item: any) => ({
                Address: item?.account_address,
                'Address System': item?.account?.account_address,
                USDT: formatAmount(item?.balance?.USDT / 10 ** 18),
                BNB: formatNumber(item?.balance?.BNB / 10 ** 18, 4, 4),
                PBC: formatAmount(item?.balance?.PBC / 10 ** 18),
                'Status withdraw': item?.no_withdraw === 1 ? 'Inactive' : 'Active',
                Time: moment(item?.created_at).format('YYYY-MM-DD HH:mm:ss'),
              }))}
              filename={`PublicWallet-${moment().format('YYYYMMDDHHmmss')}`}
              target="_blank"
              separator=";"
            >
              <Button type="primary" loading={userAll?.isLoading}>
                <ExportOutlined /> Export
              </Button>
            </CSVLink>
          </div>

          <div className="content-transaction">
            <Table
              dataSource={user?.docs}
              columns={columns}
              pagination={{
                total: user?.total,
                pageSize: user?.per_page,
                current: user?.current_page,
                onChange: (currentPage, pageSize) => {
                  setParamSearch((prev) => ({
                    ...prev,
                    page: currentPage,
                    per_page: pageSize,
                  }))
                },
              }}
              scroll={{ x: 400 }}
              loading={user?.isLoading}
            />
          </div>
        </div>
      </div>
    </WrapUser>
  )
}

export default User
