/* eslint-disable consistent-return */
import { stringify } from 'helpers'
import fetchWrapper from 'helpers/fetch-wrapper'

const apiBaseUrl = `/transaction`

const list = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/get-transaction-by-user/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe()
  })
}

const syncTransaction = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/sync-transaction-by-user/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe(data?.data)
  })
}

const swapPBCToUSDT = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`/swap/pbc`, body).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const search = async (payload: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  const qStr = payload ? stringify(payload) : ''

  return fetchWrapper.get(`${apiBaseUrl}/search?${qStr}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const totalBalance = async (cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/total-balance`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const getCommissionUsdtTransactions = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/get-transaction-commission-usdt/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe(data?.data)
  })
}

export default { list, syncTransaction, swapPBCToUSDT, search, totalBalance, getCommissionUsdtTransactions }
