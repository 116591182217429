/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'

export const FadedSpan = styled(RowFixed)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
  padding-bottom: 12px;
`

export const MenuItem = styled(RowBetween)`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0, 72px);
  grid-gap: 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
  :hover {
    background-color: ${({ theme, disabled }) => (!disabled ? '#107c83' : '')};
  }
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  outline: none;
  border-radius: 5px;
  border-style: solid;
  color: ${({ theme }) => (theme.isDark ? '#fff' : '#1C1C28')};
  border: 1px solid #776bff;
  -webkit-appearance: none;
  margin-top: 32px;

  font-size: 18px;

  ::placeholder {
    color: #5a636f;
  }
  transition: border 100ms;
  :focus {
    border: 1px solid #fde905;
    outline: none;
  }
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.invertedContrast};
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.tertiary};
`
export const LineHr = styled.hr`
  width: 60%;
  border: 0.1px solid transparent;
`
