import React, { useCallback, useEffect, useState } from 'react'
import { Button, InputNumber, Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CurrencyLogo from 'components/CurrencyLogo'
import { isMobile } from 'react-device-detect'
import { setModalConnect } from 'state/modal/actions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'components/Loader'
import { AppState } from 'state'
import { useWeb3React } from '@web3-react/core'
import useToast from 'hooks/useToast'
import useSignMessage from 'hooks/useSignMessage'
import { formatAmount } from 'utils/formatInfoNumbers'
import { accountService } from 'services'
import { getTransactionReceiptMined } from 'utils'
import { ethers } from 'ethers'

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  padding: 30px 0px 40px;
  min-height: 300px;

  @media (min-width: 576px) {
    padding: 0.5rem 14px 1rem;
  }

  .md-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 100%;

    button {
      margin: auto;
      width: 100%;
      max-width: 250px;
    }

    .box-currency {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;

      p {
        font-weight: 600;
      }

      .currency-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.05), 15px 20px 10px rgba(0, 0, 0, 0.05),
          15px 10px 10px rgba(0, 0, 0, 0.05), inset 15px 15px 15px rgba(255, 255, 255, 0.9);
        border-radius: 67% 33% 28% 72% / 59% 52% 48% 41%;
        animation: animationBalloons 5s infinite linear;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;

        ${({ theme }) => theme.mediaQueries.lg} {
          width: 80px;
          height: 80px;
        }
      }

      .arrow {
        transform: rotate(-90deg);
        cursor: pointer;

        span {
          display: block;
          width: 1.5vw;
          height: 1.5vw;
          border-bottom: 5px solid white;
          border-right: 5px solid white;
          transform: rotate(45deg);
          margin: 0;
          animation: animateArrow 1.5s linear infinite;
          opacity: 0;

          &:nth-child(2) {
            animation-delay: 0.1s;
          }
          &:nth-child(3) {
            animation-delay: 0.2s;
          }
          &:nth-child(4) {
            animation-delay: 0.3s;
          }
          &:nth-child(5) {
            animation-delay: 0.4s;
          }
          &:nth-child(6) {
            animation-delay: 0.5s;
          }
          &:nth-child(7) {
            animation-delay: 0.6s;
          }
          &:nth-child(8) {
            animation-delay: 0.7s;
          }
          &:nth-child(9) {
            animation-delay: 0.8s;
          }
        }
      }
    }

    .select-amount {
      margin-bottom: 20px;

      .box-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;

        p {
          font-size: 1rem;
          font-weight: 600;
        }
      }

      .ant-input-number {
        width: 100%;
      }
    }
  }
`

const FEE_WITHDRAW = 0.006

const ModalWithdraw = ({ modal, setModal }: { modal: any; setModal: (input: any) => void }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { account, library } = useWeb3React()
  const { toastSuccess, toastError, toastWarning } = useToast()
  const signMessage = useSignMessage()

  const walletAddressDepoist: any = useSelector<AppState>((state) => state.account.walletAddress)
  const balance: any = useSelector<AppState>((state) => state.account.balance)

  const [addressDepoistState, setAddressDepositState] = useState<string>('')
  const [inputBalance, setInputBalance] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const connectWallet = useCallback(() => {
    dispatch(setModalConnect({ toggle: true }))
  }, [dispatch])

  useEffect(() => {
    if (walletAddressDepoist?.walletAddress) {
      setAddressDepositState(walletAddressDepoist?.walletAddress as string)
    } else {
      setAddressDepositState('')
    }
  }, [walletAddressDepoist?.walletAddress])

  const handleWithdraw = useCallback(
    async (token: string) => {
      if (inputBalance <= 0) {
        toastWarning('The withdrawal amount must be greater than 0.')
        return
      }

      try {
        setLoading(true)
        await signMessage()
      } catch {
        toastWarning('You have canceled the transaction.')
        setLoading(false)
        return
      }

      const paramFee = {
        _to: addressDepoistState,
        _value: FEE_WITHDRAW,
      }

      setLoading(true)

      const paramWithdraw = {
        currency: token,
        amountWithdraw: inputBalance,
      }

      if (+balance?.balance?.BNB / 10 ** 18 >= FEE_WITHDRAW) {
        if (token === 'BNB' && +balance?.balance?.BNB / 10 ** 18 >= FEE_WITHDRAW + +inputBalance) {
          accountService.withdraw(
            paramWithdraw,
            () => {
              toastSuccess('Withdraw successful')
              setLoading(false)
              setModal({
                toogle: false,
                data: null,
              })
              setInputBalance(0)
            },
            (e) => {
              toastError(e?.error || e?.message || 'Withdraw failed.')
              setLoading(false)
            }
          )

          return
        }

        accountService.withdraw(
          paramWithdraw,
          () => {
            toastSuccess('Withdraw successful')
            setLoading(false)
            setModal({
              toogle: false,
              data: null,
            })
            setInputBalance(0)
          },
          (e) => {
            toastError(e?.error || e?.message || 'Withdraw failed.')
            setLoading(false)
          }
        )

        return
      }

      try {
        let recipt: any

        try {
          recipt = await library?.getSigner().sendTransaction({
            to: paramFee?._to,
            value: ethers.utils.parseEther(paramFee?._value?.toString()),
          })
        } catch (e: any) {
          toastWarning(e?.data?.message || 'You have canceled the transaction.')
          setLoading(false)
          return
        }

        const reciptFromHash: any = await getTransactionReceiptMined(recipt?.hash, 500)

        if (reciptFromHash?.status === true) {
          setLoading(true)

          accountService.withdraw(
            paramWithdraw,
            () => {
              toastSuccess('Withdraw successful')
              setLoading(false)
              setModal({
                toogle: false,
                data: null,
              })
              setInputBalance(0)
            },
            (e) => {
              toastError(e?.error || e?.message || 'Withdraw failed.')
              setLoading(false)
            }
          )
        } else {
          toastError('Withdraw failed')
          setLoading(false)
        }
      } catch {
        toastError('Withdraw failed')
        setLoading(false)
      }
    },
    [
      addressDepoistState,
      balance?.balance?.BNB,
      inputBalance,
      library,
      setModal,
      signMessage,
      toastError,
      toastSuccess,
      toastWarning,
    ]
  )

  return (
    <Modal
      key="modal-connect"
      visible={modal?.toggle}
      centered
      width={640}
      title={`${t('Withdraw')} ${modal?.data?.symbol}`}
      footer={null}
      onCancel={() => {
        if (!loading) {
          setModal({
            toogle: false,
            data: null,
          })
          setInputBalance(0)
        }
      }}
      closeIcon={<img src="/images/icons/close-white.png?v=1.1" alt="" />}
    >
      <ModalContent>
        <div className="md-content">
          {addressDepoistState ? (
            <>
              <div className="box-currency">
                <div>
                  <p style={{ opacity: 0 }}>To Public Wallet</p>
                  <div className="currency-item">
                    <img src="/images/logo.png" width={isMobile ? '30px' : '50px'} alt="" />
                  </div>
                  <p style={{ textAlign: 'center' }}>From Public Wallet</p>
                </div>

                <div className="arrow">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>

                <div>
                  <p style={{ opacity: 0 }}>From BSC</p>
                  <div className="currency-item">
                    <CurrencyLogo currency={modal?.data} size={isMobile ? '30px' : '50px'} />
                  </div>
                  <p style={{ textAlign: 'center' }}>To BSC</p>
                </div>
              </div>

              <div className="select-amount">
                <div className="box-label">
                  <span>
                    Balance:{' '}
                    {!balance?.isLoading ? (
                      formatAmount(balance?.balance[`${modal?.data?.symbol}`] / 10 ** 18)
                    ) : account ? (
                      <Loader />
                    ) : (
                      0
                    )}
                  </span>
                </div>
                <InputNumber
                  onChange={(e) => setInputBalance(+e)}
                  value={+inputBalance}
                  disabled={!account && !balance?.balance[`${modal?.data?.symbol}`]}
                  max={balance?.balance?.[`${modal?.data?.symbol}`] ? balance.balance[`${modal?.data?.symbol}`] / 10 ** 18 : 0}
                  min={0}
                />
                <p style={{ color: '#FFFFCC' }}>Fee: {FEE_WITHDRAW} BNB</p>
              </div>

              <div>
                <p style={{ fontSize: 12, color: '#FFFFCC' }}>
                  <span style={{ display: 'contents', color: 'red' }}>*</span>Note: The token withdrawal process may take 3 to 10
                  minutes. Please do not close your browser!
                </p>
              </div>

              {account ? (
                <Button type="primary" onClick={() => handleWithdraw(modal?.data?.symbol)} loading={loading}>
                  Confirm
                </Button>
              ) : (
                <Button type="primary" onClick={connectWallet}>
                  Connect Wallet
                </Button>
              )}
            </>
          ) : (
            <>
              {account ? (
                <Button
                  type="primary"
                  onClick={async () => {
                    try {
                      setLoading(true)
                      await signMessage(setLoading)
                    } catch {
                      toastWarning('You have canceled the transaction.')
                      setLoading(false)
                    }
                  }}
                  loading={loading || walletAddressDepoist?.isLoading}
                >
                  {loading && <Spin indicator={<LoadingOutlined spin />} size="small" />}
                  Get Address Deposit
                </Button>
              ) : (
                <Button type="primary" onClick={connectWallet}>
                  Connect Wallet
                </Button>
              )}
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalWithdraw)
