import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CopyToClipboard from 'components/CoppyItem/CopyToClipboardInput'
import QRCode from 'react-qr-code'
import { useDispatch, useSelector } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import { AppState } from 'state'
import { useWeb3React } from '@web3-react/core'
import useSignMessage from 'hooks/useSignMessage'

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  padding: 30px 0px 40px;
  min-height: 300px;

  @media (min-width: 576px) {
    padding: 0.5rem 14px 1rem;
  }

  .md-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 100%;

    .box-qr {
      display: flex;
      justify-content: center;
      align-item: center;
      margin-bottom: 15px;

      .content-qr {
        padding: 20px;
        background: #fff;

        svg {
          width: 150px;
          height: 150px;
        }
      }
    }

    button {
      margin: auto;
      max-width: 250px;
    }
  }
`

const ModalDepositQR = ({ toggle, setToggle }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const signMessage = useSignMessage()

  const walletAddressDepoist: any = useSelector<AppState>((state) => state.account.walletAddress)

  const [addressDepoist, setAddressDepoist] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const connectWallet = useCallback(() => {
    dispatch(setModalConnect({ toggle: true }))
  }, [dispatch])

  useEffect(() => {
    if (walletAddressDepoist?.walletAddress) {
      setAddressDepoist(walletAddressDepoist?.walletAddress)
    } else {
      setAddressDepoist('')
    }
  }, [walletAddressDepoist?.walletAddress])

  return (
    <Modal
      key="modal-connect"
      visible={toggle}
      centered
      width={640}
      title={t('Deposit')}
      footer={null}
      onCancel={() => setToggle(false)}
      closeIcon={<img src="/images/icons/close-white.png?v=1.1" alt="" />}
    >
      <ModalContent>
        <div className="md-content">
          {addressDepoist ? (
            <>
              <div className="box-qr">
                <div className="content-qr">
                  <QRCode value={addressDepoist} />
                </div>
              </div>
              <CopyToClipboard value={addressDepoist} title="">
                {addressDepoist}
              </CopyToClipboard>
            </>
          ) : (
            <>
              {account ? (
                <Button
                  type="primary"
                  onClick={() => signMessage(setLoading)}
                  loading={loading || walletAddressDepoist?.isLoading}
                >
                  Get Address Deposit
                </Button>
              ) : (
                <Button type="primary" onClick={connectWallet}>
                  Connect Wallet
                </Button>
              )}
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalDepositQR)
