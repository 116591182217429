/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import Header from 'components/Header'
import Footer from 'components/Footer'

const StyleLayout = styled.div`
  position: relative;
  z-index: 0;
  max-width: 100%;

  &.theme-dark {
    * {
      color: #fff;
    }
  }

  .content {
    .content-page {
      padding-left: 0;

      ${({ theme }) => theme.mediaQueries.lg} {
        padding-left: 250px;
      }
    }
  }
`

const CustomLayout: React.FC<React.PropsWithChildren<{ children: React.ReactNode }>> = ({ children }) => {
  const { isDark } = useTheme()

  return (
    <StyleLayout className={`${isDark ? 'theme-dark' : ''}`}>
      <Header />
      <div className="content">
        <div className="content-page">
          <div>{children}</div>
        </div>
      </div>
      <Footer />
    </StyleLayout>
  )
}

export default CustomLayout
