/* eslint-disable react/react-in-jsx-scope */
import styled, { useTheme } from 'styled-components'
import DownIcon from 'assets/images/down-icon.svg'
import DownIconBlack from 'assets/images/down-icon-black.svg'

const Wrapper = styled.div`
  background: #555770;
  border: 1px solid #8f90a6;
  border-radius: 12px;
  height: 56px;
  padding: 12px 16px;

  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  .token-logo {
    width: 24px;
    height: 24px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
    border-radius: 24px;
  }
`

function SelectButton({ image, name }) {
  const { isDark } = useTheme()

  return (
    <Wrapper>
      <img src={image} alt="logo" className="token-logo" />
      <p className="token-name">{name}</p>
      <img style={{ marginLeft: '6px' }} src={isDark ? DownIcon : DownIconBlack} width={13} alt="" />
    </Wrapper>
  )
}

export default SelectButton
