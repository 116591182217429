/* eslint-disable no-param-reassign */

export const formatNumber = (number: number, minPrecision = 2, maxPrecision = 2) => {
  const options = {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  }
  return number.toLocaleString(undefined, options)
}

export type formatAmountNotation = 'compact' | 'standard'

/**
 * This function is used to format token prices, liquidity, amount of tokens in TX, and in general any numbers on info section
 * @param amount - amount to be formatted
 * @param notation - whether to show 1M or 1,000,000
 * @param displayThreshold - threshold below which it will return simply <displayThreshold instead of actual value, e.g. if 0.001 -> returns <0.001 for 0.0005
 * @param tokenPrecision - set to true when you want precision to be 3 decimals for values < 1 and 2 decimals for values > 1
 * @param isInteger - if true the values will contain decimal part only if the amount is > 1000
 * @returns formatted string ready to be displayed
 */
export const formatAmountOrigin = (
  amount: number | undefined,
  options?: {
    notation?: formatAmountNotation
    displayThreshold?: number
    tokenPrecision?: boolean
    isInteger?: boolean
  }
) => {
  const { displayThreshold, tokenPrecision, isInteger } = options || { notation: 'compact' }
  if (amount === 0) {
    if (isInteger) {
      return '0'
    }
    return '0.00'
  }
  if (!amount) return '-'

  if (displayThreshold && amount < displayThreshold) {
    return `<${displayThreshold}`
  }

  let precision = 2
  if (tokenPrecision) {
    precision = amount < 1 ? 3 : 2
  }

  if (amount < 1 && !tokenPrecision) {
    return amount.toFixed(precision)
  }

  const abbreviations = ['', 'K', 'M', 'B', 'T', 'Q']

  let notationIndex = 0
  while (amount >= 1000 && notationIndex < abbreviations.length - 1) {
    amount /= 1000
    notationIndex++
  }

  const formattedAmount = (Math.floor(amount * 100) / 100).toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })

  return `${formattedAmount}${abbreviations[notationIndex]}`
}

export const formatAmount = (amount = 0) => {
  return formatNumber(amount)
}
