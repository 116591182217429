/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { FieldTimeOutlined } from '@ant-design/icons'
import { Flex } from 'poodlefi-libs-uikit'
import { Table, Tooltip } from 'antd'
import { shortenString } from 'utils'
import { formatAmount } from 'utils/formatInfoNumbers'
import moment from 'moment'
import { ADDRESS_WITHDRAW } from 'constants/index'
import { transactionService } from 'services'

const WrapTransactions = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem;
    border-radius: 1.5rem;

    .header-transaction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 16px;
      margin-bottom: 20px;
    }
  }
`

const columns = [
  {
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
    render: (hash) =>
      `${hash}`?.length === 66 ? (
        <a href={`https://bscscan.com/tx/${hash}`} target="_Blank" rel="noreferrer">
          {shortenString(hash)}
        </a>
      ) : (
        <p>{hash}</p>
      ),
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
    render: (address) =>
      address === ADDRESS_WITHDRAW ? (
        'WITHDRAW'
      ) : (
        <>
          {`${address}`?.length === 42 ? (
            <a href={`https://bscscan.com/address/${address}`} target="_Blank" rel="noreferrer">
              {shortenString(address)}
            </a>
          ) : (
            <p>{address}</p>
          )}
        </>
      ),
  },
  {
    title: 'Token',
    dataIndex: 'token_symbol',
    key: 'token_symbol',
    render: (token_symbol) => <p>{token_symbol}</p>,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value, record) => {
      const balance = value / 10 ** 18
      return (
        <p>
          {record?.from === ADDRESS_WITHDRAW && '-'}
          {balance < 0.01 && balance > -0.01 ? `${balance}`.slice(0, 6) : formatAmount(balance)}
        </p>
      )
    },
  },
  {
    title: 'Time',
    dataIndex: 'timeStamp',
    key: 'timeStamp',
    render: (timeStamp) => (
      <p>
        <Tooltip placement="top" title={moment.unix(timeStamp).format('YYYY-MM-DD HH:mm:ss')}>
          {moment.unix(timeStamp).fromNow()}
        </Tooltip>
      </p>
    ),
  },
]

const Transactions = () => {
  const { isDark } = useTheme()

  const [transactions, setTransactions] = useState({
    isLoading: true,
    docs: [],
  })
  const [paramSearch] = useState()

  useEffect(() => {
    transactionService.search(paramSearch, (data) => {
      setTransactions({
        ...data?.data,
        isLoading: false,
      })
    })
  }, [paramSearch])

  return (
    <WrapTransactions className={isDark ? 'theme-dark' : ''}>
      <div className="container">
        <div className="inner-content">
          <div className="header-transaction">
            <Flex alignItems="center">
              <FieldTimeOutlined style={{ marginRight: '8px', fontSize: '22px' }} /> Recent Transactions
            </Flex>
          </div>

          <div className="content-transaction">
            <Table
              dataSource={transactions?.docs}
              columns={columns}
              pagination={{
                total: transactions?.docs?.length,
                defaultPageSize: 100,
              }}
              scroll={{ x: 400 }}
              loading={transactions?.isLoading}
            />
          </div>
        </div>
      </div>
    </WrapTransactions>
  )
}

export default Transactions
