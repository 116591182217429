/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Modal } from 'antd'
import { useActiveWeb3React } from 'hooks'
import styled from 'styled-components'
import CopyToClipboard from 'components/CoppyItem/CopyToClipboardInput'

import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

const ModalContent = styled.div`
  padding: 30px 0px 40px;
  @media (min-width: 576px) {
    padding: 0.5rem 14px 1rem;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #f6f8fa;
    margin-bottom: 32px;

    a {
      color: #3772ff;
    }
  }

  .btn-logout {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #fff;
    height: 45px;
    background: #0063f7;
    border-radius: 12px;
    border: none;
    padding: 4px 24px;
    cursor: pointer;
  }
`

const WrapperAccount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  .btn-logout {
    width: fit-content;
    margin: 0 auto;
  }
`

const ModalConnect = ({ toggle, setToggle }) => {
  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const handleLogout = () => {
    logout()
    setToggle(false)
  }

  return (
    <Modal
      key="modal-connect"
      visible={toggle}
      centered
      width={830}
      title={t('YOUR WALLET')}
      footer={null}
      onCancel={() => setToggle(false)}
      closeIcon={<img src="/images/icons/close-white.png?v=1.1" alt="" />}
    >
      <ModalContent>
        <WrapperAccount>
          <CopyToClipboard title={t('Your address')} value={account || ''}>
            {account}
          </CopyToClipboard>
          <button type="button" className="btn-logout" onClick={() => handleLogout()}>
            {t('Logout')}
          </button>
        </WrapperAccount>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalConnect)
