import { Col, Modal, Row } from 'antd'
import useAuthV2 from 'hooks/useAuthV2'
import { CloseIcon, Flex, Text } from 'poodlefi-libs-uikit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import styled from 'styled-components'
import { AppState } from '../../state/index'
import WalletCard from './WalletCard'
import config from './config'

const ModalContent = styled.div`
  .header {
    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: #fff;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 21px;
        line-height: 32px;
      }
    }
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;

      path {
        fill: #000;
      }
    }
  }

  .policy,
  .policy span {
    font-size: 14px;
    font-weight: 400;
  }

  .connect-btn {
    padding: 0;
    width: 100%;
    margin: 24px auto 0;
    display: block;

    ${({ theme }) => theme.mediaQueries.sm} {
      max-width: 160px;
    }

    * {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0;
    }

    > div {
      all: unset;
      display: block;
      padding: 18px;

      :hover {
        all: unset;
        display: block;
        padding: 18px;
      }
    }
  }
`

const ModalConnectWallet = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { login } = useAuthV2()
  const { toggle } = useSelector((state: AppState) => state.modal.modalConnect)

  const handleCancel = () => {
    dispatch(setModalConnect({ toggle: false }))
  }

  return (
    <Modal
      key="modal-connect"
      className="modal-connect-wallet"
      visible={toggle}
      centered
      width={448}
      footer={null}
      closeIcon={null}
      onCancel={handleCancel}
      closable={false}
      maskStyle={{
        backgroundColor: 'rgba(0,0,0,0.25)',
      }}
      bodyStyle={{
        boxShadow: '0px 0px 24px 0px rgba(255, 255, 255, 0.25)',
        borderRadius: '12px',
      }}
    >
      <ModalContent>
        <div className="relative">
          <Flex justifyContent="space-between" alignItems="center" mb="24px" className="header">
            <p className="title">{t('Connect to a Wallet')}</p>
            <CloseIcon onClick={handleCancel} />
          </Flex>

          <Text color="#fff" fontSize="14px" lineHeight="16px" textAlign="center" mb="24px">
            {t('By connecting a wallet, you agree to')}{' '}
            <Text as="span" color="#3772FF" fontSize="14px" lineHeight="16px">
              {t('Terms of Service')}
            </Text>{' '}
            {t('and acknowledge that you have read and understand the')}{' '}
            <Text as="span" color="#3772FF" fontSize="14px" lineHeight="16px">
              {t('disclaimer')}
            </Text>
            .
          </Text>

          <Row gutter={[16, 16]} justify="center">
            {config.map((entry) => (
              <Col key={entry.title} span={24} className="connect-logo">
                <WalletCard key={entry.title} walletConfig={entry} login={login} onDismiss={handleCancel} />
              </Col>
            ))}
          </Row>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalConnectWallet)
