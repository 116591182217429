/* eslint-disable react/react-in-jsx-scope */
import useTheme from 'hooks/useTheme'
import { Button, IconButton } from 'poodlefi-libs-uikit'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from 'hooks'
import Nav from 'components/CardNav'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { Skeleton, Spin } from 'antd'
import { transactionService } from 'services'
import useSignMessage from 'hooks/useSignMessage'
import { LoadingOutlined } from '@ant-design/icons'
import useToast from 'hooks/useToast'

import { Input as NumericalInput } from '../../components/NumericalInput'
import SelectButton from './components/SelectButton'
import Setting from './components/Setting'

const WrapSwapOffChain = styled.div`
  width: 100%;

  padding-top: 50px;

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
  }

  &.theme-dark {
    .inner-content {
      background-color: rgb(32 27 67 / 1);

      .bg-sw {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
        opacity: 0.05;
        z-index: -1;
      }
    }
  }

  .inner-content {
    position: relative;
    width: 100%;
    z-index: 5;
    border-radius: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #ffffff;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);

    > p {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .wrap-content {
      width: 100%;
      overflow: auto;
      padding-bottom: 50px;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      .wrap-tree {
        width: max-content;
        margin: 0 auto;
      }
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 20px 15px;

  > div:first-child {
    padding: 0;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 24px;

  .icon-btn {
    background: #555770;
    width: 44px;
    height: 44px;
    border-radius: 100%;
  }
`

const SwapItem = styled.div`
  width: 100%;
  background-color: rgba(40, 41, 61, 0.9);
  border-radius: 12px;
  box-shadow: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
  padding: 1.25rem 0.75rem 1.45rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 4px;

  .amount-input {
    width: 100%;
    display: flex;
    align-items: flex-start;

    > input {
      flex: 1;
      color: #8f90a6 !important;
      height: 56px;
    }
  }

  .error-msg {
    font-size: 13px;
    color: #f30705 !important;
  }

  .wrap-max {
    display: flex;
    algin-items: center;
    justify-content: flex-end;

    p {
      display: flex;
      align-items: center;
      font-weight: 400;
      line-height: 1.5;
      font-size: 14px;
    }

    button {
      height: 32px;
      padding: 0 16px;
      font-size: 16px;
      font-weight: 600;
    }
  }
`

const ButtonGroups = styled.div`
  width: 100%;
  margin-top: 2rem;

  button {
    width: 100%;
  }
`

const SwapOffChain = () => {
  const { isDark } = useTheme()
  const { account } = useActiveWeb3React()
  const signMessage = useSignMessage()
  const { toastError, toastSuccess, toastWarning } = useToast()

  const balance: any = useSelector<AppState>((state) => state.account.balance)

  const [amountInput, setAmountInput] = useState(0)
  const [amountOutput, setAmountOutput] = useState(0)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setAmountOutput(amountInput * 0.0003)
  }, [amountInput, amountOutput])

  useEffect(() => {
    if (amountInput > balance?.balance?.PBC / 10 ** 18) {
      setErrorMsg(`You don't have enough Tokens`)
    } else {
      setErrorMsg('')
    }
  }, [amountInput, balance?.balance?.PBC])

  const handleSwap = useCallback(async () => {
    if (amountInput <= 0) {
      setErrorMsg('Amount must be greater than 0')
      return
    }

    try {
      setLoading(true)
      await signMessage(setLoading)
    } catch {
      toastWarning('You have canceled the transaction.')
      setLoading(false)
      return
    }

    const _body = {
      amount: amountInput,
    }

    await transactionService.swapPBCToUSDT(
      _body,
      (data) => {
        setErrorMsg('')
        setAmountInput(0)
        setLoading(false)
        toastSuccess(data?.message || 'Swap successfuly.')
      },
      (e) => {
        toastError(e?.error || e?.message || 'Swap failed.')
      }
    )
  }, [amountInput, signMessage, toastError, toastSuccess, toastWarning])

  return (
    <>
      <WrapSwapOffChain className={isDark ? 'theme-dark' : ''}>
        <div className="container">
          <div className="inner-content">
            <img className="bg-sw" src="/images/logo.png" alt="" />

            <Header>
              <Nav />
              <Setting isDark={isDark} />
            </Header>

            <Body>
              <SwapItem>
                <div className="amount-input">
                  <NumericalInput
                    className="token-amount-input"
                    value={amountInput}
                    onUserInput={(val) => {
                      // if (+val > balance?.balance?.PBC / 10 ** 18) {
                      //   setAmountInput(balance?.balance?.PBC / 10 ** 18)
                      // } else {
                      setAmountInput(+val)
                      // }
                    }}
                  />
                  <div>
                    <SelectButton image="/images/logo.png" name="PBC" />
                    <div className="wrap-max">
                      {balance.isLoading ? (
                        <Skeleton.Button active size="large" />
                      ) : (
                        <>
                          <p>Balance: {`${balance?.balance?.PBC / 10 ** 18}`.slice(0, 5)}</p>{' '}
                          <button type="button" onClick={() => setAmountInput(balance?.balance?.PBC / 10 ** 18)}>
                            Max
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {errorMsg && <p className="error-msg">{errorMsg}</p>}
              </SwapItem>

              <IconButton variant="tertiary" className="icon-btn" onClick={() => true} scale="sm" style={{ cursor: 'no-drop' }}>
                <img style={{ width: '24px' }} src="/images/custom-icon/icon-swap.png" alt="swap" />
              </IconButton>

              <SwapItem>
                <div className="amount-input">
                  <NumericalInput
                    className="token-amount-output"
                    value={amountOutput}
                    onUserInput={(val) => {
                      setAmountOutput(+val)
                    }}
                    disabled
                  />
                  <SelectButton image="/images/coins/USDT.png" name="USDT" />
                </div>
              </SwapItem>

              <ButtonGroups>
                {!account ? (
                  <ConnectWalletButton />
                ) : (
                  <Button onClick={handleSwap} isLoading={loading}>
                    {loading ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : 'Swap'}
                  </Button>
                )}
              </ButtonGroups>
            </Body>
          </div>
        </div>
      </WrapSwapOffChain>
    </>
  )
}

export default SwapOffChain
