/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useEffect, useRef, useState } from 'react'
import { referralService } from 'services'
import { shortenString } from 'utils'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, Empty } from 'antd'
import { formatNumber } from 'utils/formatInfoNumbers'

const WrapReferral = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem 10px;
    border-radius: 1.5rem;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 1.5rem 15px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 1.5rem;
    }

    .title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      p {
        font-size: 1.5rem;
        font-weight: 600;

        &:nth-child(2) {
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }

    .wrap-referral {
      width: 100%;
      overflow: auto;
      padding-bottom: 50px;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      .wrap-tree {
        width: max-content;
        margin: 0 auto;
      }
    }
  }
`

const WrapTree = styled.div`
  > .root-address {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 26px;
    border-bottom: 1px solid #fff;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(100% + 8px));
      width: 1px;
      height: 26px;
      background: #fff;
    }

    p {
      width: 110px;
      padding: 8px 0;
      text-align: center;
      border: 1px solid #fff;
    }
  }

  > .root-child {
    display: flex;
    margin-top: 26px;

    > div {
      padding: 0 10px;
    }
  }
`
const WrapTreeNode = styled.div`
  width: 100%;

  &:last-child {
    > .box-address {
      p {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(0.5px, -119%);
          width: calc(50% + 11px);
          height: 26px;
          background: rgb(32 27 67 / 1);
        }
      }
    }
  }

  &:first-child {
    > .box-address {
      p {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 50%;
          transform: translate(-0.5px, -119%);
          width: calc(50% + 11px);
          height: 26px;
          background: rgb(32 27 67 / 1);
        }
      }
    }
  }

  > .box-address {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
      width: 1px;
      height: 26px;
      background: #fff;
    }

    &.has-child {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(100% + 8px));
        width: 1px;
        height: 26px;
        background: #fff;
      }
    }

    p {
      width: 110px;
      padding: 8px 0;
      text-align: center;
      border: 1px solid #fff;
    }
  }

  > .box-child {
    padding-top: 26px;
    border-top: 1px solid #fff;
    margin-top: 26px;

    > div {
      padding: 0 10px;
    }
  }
`

const TreeNode = ({ node }) => (
  <WrapTreeNode>
    <div className={`box-address ${node?.children?.length > 0 ? 'has-child' : ''}`} style={{ textAlign: 'center' }}>
      <a href={`https://bscscan.com/address/${node?.ref_code}`} target="_Blank" rel="noreferrer">
        <p>{shortenString(node?.ref_code)}</p>
      </a>
    </div>
    {node?.children?.length > 0 && (
      <div className="box-child" style={{ display: 'flex', justifyContent: 'center' }}>
        {node?.children?.map((child) => (
          <TreeNode key={child?.id} node={child} />
        ))}
      </div>
    )}
  </WrapTreeNode>
)

const Tree = ({ data }) => (
  <WrapTree>
    <div className="root-address">
      <a href={`https://bscscan.com/address/${data?.ref_code}`} target="_Blank" rel="noreferrer">
        <p>You</p>
      </a>
    </div>
    {data?.children?.length > 0 && (
      <div className="root-child">
        {data?.children?.map((child) => (
          <TreeNode key={child?.id} node={child} />
        ))}
      </div>
    )}
  </WrapTree>
)

const Referral = () => {
  const { isDark } = useTheme()
  const account = '0xBe6884fd73A45343277eC21a3f7BdedF44B06999'
  const wrapReferralRef = useRef<any>(null)

  const [dataTree, setDataTree] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingGetNumberOfChild, setLoadingGetNumberOfChild] = useState<boolean>(true)
  const [numberOfChild, setNumberOfChild] = useState<number>(0)
  const [totalUserDeposit, setTotalUserDeposit] = useState<number>(0)

  useEffect(() => {
    if (account) {
      setLoading(true)
      referralService.getTree(
        account,
        (data) => {
          setDataTree(data?.docs)
          setLoading(false)
        },
        () => {
          setLoading(false)
        }
      )
    } else {
      setLoading(false)
    }
  }, [account])

  useEffect(() => {
    if (account) {
      setLoadingGetNumberOfChild(true)
      referralService.getNumberOfChild(
        account,
        (data) => {
          setNumberOfChild(data?.qty)
          setTotalUserDeposit(data?.totalDeposit)
          setLoadingGetNumberOfChild(false)
        },
        () => {
          setLoadingGetNumberOfChild(false)
        }
      )
    }
  }, [account])

  useEffect(() => {
    if (wrapReferralRef?.current && account && !loading) {
      const wrapReferral = wrapReferralRef?.current
      const wrapTree = wrapReferral.querySelector('.wrap-tree')

      if (wrapReferral && wrapTree) {
        const centerPosition = (wrapTree.offsetWidth - wrapReferral.clientWidth) / 2
        wrapReferral.scrollTo({
          left: centerPosition,
          behavior: 'smooth',
        })
      }
    }
  }, [loading])

  return (
    <>
      <WrapReferral className={isDark ? 'theme-dark' : ''}>
        <div className="container">
          <div className="inner-content">
            <div className="title-box">
              <p>Referral</p>

              <p>
                Total:{' '}
                {loadingGetNumberOfChild ? (
                  <Spin indicator={<LoadingOutlined spin />} size="small" />
                ) : (
                  formatNumber(totalUserDeposit / 10 ** 18)
                )}
                $
                <br />
                <br />
                User: {loadingGetNumberOfChild ? <Spin indicator={<LoadingOutlined spin />} size="small" /> : numberOfChild}{' '}
              </p>
            </div>

            <div className="wrap-referral" ref={wrapReferralRef}>
              {account ? (
                <>
                  {!loading ? (
                    <div className="wrap-tree">
                      <Tree data={dataTree} />
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Spin indicator={<LoadingOutlined spin />} size="small" />
                    </div>
                  )}
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </div>
        </div>
      </WrapReferral>
    </>
  )
}

export default Referral
