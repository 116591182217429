/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useCallback, useEffect, useState } from 'react'
import { FieldTimeOutlined, SyncOutlined } from '@ant-design/icons'
import { Flex } from 'poodlefi-libs-uikit'
import useToast from 'hooks/useToast'
import { Table, Tooltip } from 'antd'
import { shortenString } from 'utils'
import { accountService, transactionService } from 'services'
import { useActiveWeb3React } from 'hooks'
import { formatAmount } from 'utils/formatInfoNumbers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'state'
import { setBalance, setTransactions } from 'state/account/actions'
import { ADDRESS_WITHDRAW } from 'constants/index'

const WrapTransactions = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem;
    border-radius: 1.5rem;

    .header-transaction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 16px;
      margin-bottom: 20px;
    }
  }
`

const columns = [
  {
    title: 'Hash',
    dataIndex: 'hash',
    key: 'hash',
    render: (hash) =>
      `${hash}`?.length === 66 ? (
        <a href={`https://bscscan.com/tx/${hash}`} target="_Blank" rel="noreferrer">
          {shortenString(hash)}
        </a>
      ) : (
        <p>{hash}</p>
      ),
  },
  {
    title: 'From',
    dataIndex: 'from',
    key: 'from',
    render: (address) =>
      address === ADDRESS_WITHDRAW ? (
        'WITHDRAW'
      ) : (
        <>
          {`${address}`?.length === 42 ? (
            <a href={`https://bscscan.com/address/${address}`} target="_Blank" rel="noreferrer">
              {shortenString(address)}
            </a>
          ) : (
            <p>{address}</p>
          )}
        </>
      ),
  },
  {
    title: 'Token',
    dataIndex: 'token_symbol',
    key: 'token_symbol',
    render: (token_symbol) => <p>{token_symbol}</p>,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value, record) => {
      const balance = value / 10 ** 18
      return (
        <p>
          {record?.from === ADDRESS_WITHDRAW && '-'}
          {balance < 0.01 && balance > -0.01 ? `${balance}`.slice(0, 6) : formatAmount(balance)}
        </p>
      )
    },
  },
  {
    title: 'Time',
    dataIndex: 'timeStamp',
    key: 'timeStamp',
    render: (timeStamp) => (
      <p>
        <Tooltip placement="top" title={moment.unix(timeStamp).format('YYYY-MM-DD HH:mm:ss')}>
          {moment.unix(timeStamp).fromNow()}
        </Tooltip>
      </p>
    ),
  },
]

const Transactions = () => {
  const dispatch = useDispatch()
  const { isDark } = useTheme()
  const { toastSuccess } = useToast()
  const { account } = useActiveWeb3React()

  const transactions: any = useSelector<AppState>((state) => state.account.transactions)
  const walletAddressDepoist: any = useSelector<AppState>((state) => state.account.walletAddress)

  const [isChecking, setIsChecking] = useState<boolean>(false)

  const getBalance = useCallback(() => {
    if (account) {
      accountService.getBalance(account, (data) => {
        dispatch(
          setBalance({
            balance: data,
            isLoading: false,
          })
        )
      })
    }
  }, [account, dispatch])

  const getListTransaction = useCallback(() => {
    if (account) {
      setIsChecking(true)
      transactionService.list(account, (data) => {
        dispatch(setTransactions({ docs: data?.docs, isLoading: false }))
        setIsChecking(false)
      })
    }
  }, [account, dispatch])

  const checkTransactions = useCallback(
    (isNoti = true) => {
      if (account) {
        setIsChecking(true)
        transactionService.syncTransaction(
          account,
          () => {
            getListTransaction()
            getBalance()
            setIsChecking(false)
            if (isNoti) toastSuccess('Check Success!')
          },
          (e) => {
            setIsChecking(false)
            if (isNoti) toastSuccess(e?.message || e?.error || 'Check fail!')
          }
        )
      }
    },
    [account, getBalance, getListTransaction, toastSuccess]
  )

  useEffect(() => {
    if (walletAddressDepoist?.walletAddress) {
      checkTransactions(false)
    }
  }, [checkTransactions, walletAddressDepoist?.walletAddress])

  return (
    <WrapTransactions className={isDark ? 'theme-dark' : ''}>
      <div className="container">
        <div className="inner-content">
          <div className="header-transaction">
            <Flex alignItems="center">
              <FieldTimeOutlined style={{ marginRight: '8px', fontSize: '22px' }} /> Transactions
            </Flex>
            <button
              className="text-secondary"
              type="button"
              onClick={checkTransactions}
              disabled={isChecking || transactions?.isLoading}
            >
              Check <SyncOutlined style={{ marginLeft: '4px', fontSize: '15px' }} spin={isChecking || transactions?.isLoading} />
            </button>
          </div>

          <div className="content-transaction">
            <Table
              dataSource={transactions?.docs}
              columns={columns}
              pagination={{
                total: transactions?.length,
                defaultPageSize: 5,
              }}
              scroll={{ x: 400 }}
              loading={transactions?.isLoading || isChecking}
            />
          </div>
        </div>
      </div>
    </WrapTransactions>
  )
}

export default Transactions
