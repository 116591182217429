/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'

import DepositedAssets from './components/DepositedAssets'
import Transactions from './components/Transactions'
import Assets from './components/Assets'

const WrapDashboardPage = styled.div``

const DashboardPage = () => {
  const { isDark } = useTheme()

  return (
    <WrapDashboardPage className={isDark ? 'theme-dark' : ''}>
      <DepositedAssets />
      <Transactions />
      <Assets />
    </WrapDashboardPage>
  )
}

export default DashboardPage
