import { connecterId } from 'connectors/connectorsV2'
import { Link, useMatchBreakpoints } from 'poodlefi-libs-uikit'
import React from 'react'
import styled from 'styled-components'
import { Config, Login } from './types'

const WalletStyled = styled.div`
  width: 100%;
  padding: 16px;
  color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #5b8def;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;

  .modal-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    transition: 0.5s ease;
    color: #fff;
  }
  img {
    width: 24px;
    height: 24px;
    transition: 0.5s ease;
  }

  &:hover {
    transform: scale(1.02);
  }
`

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon } = walletConfig
  const { isSm } = useMatchBreakpoints()

  let linkAction: any = {
    onClick: () => {
      login(walletConfig.connectorId)
      window.localStorage.setItem(connecterId, walletConfig.connectorId)
      onDismiss()
    },
  }

  if (typeof window !== 'undefined' && !window.ethereum && walletConfig.href && isSm) {
    linkAction = {
      style: {
        textDecoration: 'none',
      },
      as: Link,
      href: walletConfig.href,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  return (
    <WalletStyled id={`wallet-connect-${title.toLocaleLowerCase()}`} {...linkAction}>
      {title && <div className="modal-title">{title}</div>}
      {icon && <img src={icon} alt="logo" />}
    </WalletStyled>
  )
}

export default WalletCard
