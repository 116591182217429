import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface ApplicationState {
  walletAddress: {
    walletAddress: string
    isLoading: boolean
  }
  transactions: { docs: any[]; isLoading: boolean }
  balance: {
    balance: {
      PBC: number
      BNB: number
      USDT: number
    }
    isLoading: boolean
  }
}

const initialState: ApplicationState = {
  walletAddress: {
    walletAddress: '',
    isLoading: true,
  },
  transactions: {
    docs: [],
    isLoading: true,
  },
  balance: {
    balance: {
      PBC: 0,
      BNB: 0,
      USDT: 0,
    },
    isLoading: true,
  },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setAddressDeposit, (state, { payload }) => {
      state.walletAddress = payload
    })
    .addCase(actions.setTransactions, (state, { payload }) => {
      state.transactions = payload
    })
    .addCase(actions.setBalance, (state, { payload }) => {
      state.balance = payload
    })
)
