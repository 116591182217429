/* eslint-disable react/react-in-jsx-scope */
import { Currency } from '@pancakeswap/sdk'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useActiveWeb3React } from 'hooks'
import { LoadingOutlined } from '@ant-design/icons'
import CurrencyLogo from 'components/CurrencyLogo'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { formatAmount, formatNumber } from 'utils/formatInfoNumbers'
import { Spin } from 'antd'

const StyledBalanceText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

function Balance({ balance, ...props }: { balance: number; color?: string }) {
  return (
    <StyledBalanceText title={formatAmount(balance / 10 ** 18)} {...props}>
      {balance <= 0 ? '0.00' : formatNumber(balance / 10 ** 18, 4, 4)}
    </StyledBalanceText>
  )
}

const ItemAsset = ({
  currency,
  setModalDeposit,
  setModalWithdraw,
  isWithdraw,
}: {
  currency: Currency
  setModalDeposit: (input: any) => void
  setModalWithdraw: (input: any) => void
  isWithdraw: boolean
}) => {
  const { isDark } = useTheme()
  const { account } = useActiveWeb3React()
  const balance: any = useSelector<AppState>((state) => state.account.balance)

  return (
    <>
      <td>
        <div className="wrap-logo">
          <CurrencyLogo currency={currency} size="30px" />
          <div className="wrap-info">
            <p>{currency.name}</p>
            <p>{currency.symbol}</p>
          </div>
        </div>
      </td>
      <td>
        {balance?.isLoading ? (
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        ) : account ? (
          <Balance balance={balance?.balance[`${currency.symbol}`]} color={isDark ? '#fff' : '#1C1C28'} />
        ) : (
          0
        )}
      </td>
      <td>
        {(currency.symbol === 'BNB' || currency.symbol === 'PBC' || currency.symbol === 'USDT') && (
          <button
            type="button"
            onClick={() =>
              setModalDeposit({
                toggle: true,
                data: currency,
              })
            }
          >
            Deposit
          </button>
        )}
      </td>
      <td>
        {(currency.symbol === 'BNB' || currency.symbol === 'PBC' || currency.symbol === 'USDT') && (
          <button
            type="button"
            onClick={() =>
              setModalWithdraw({
                toggle: true,
                data: currency,
              })
            }
            disabled={!isWithdraw}
          >
            Withdraw
          </button>
        )}
      </td>
    </>
  )
}

export default ItemAsset
