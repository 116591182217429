import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountService, authService, transactionService } from 'services'
import { setAddressDeposit, setBalance, setTransactions } from 'state/account/actions'
import { uuid } from 'uuidv4'
import { AppState } from 'state'
import { setModalSignMessage } from 'state/modal/actions'

import useToast from './useToast'

const useSignMessage = () => {
  const dispatch = useDispatch()
  const { account, library } = useWeb3React()
  const { toastError } = useToast()
  const walletAddressDepoist: any = useSelector<AppState>((state) => state.account.walletAddress)
  const { toggle } = useSelector((state: AppState) => state.modal.modalConnect)

  const signMessage = useCallback(
    async (setLoading?: (input: boolean) => void) => {
      if (library && account) {
        const loginUUID = uuid()
        const message = `Welcome to PublicWallet. Click to authenticate the user. This request will not trigger a blockchain transaction or cost any gas fees. Wallet address: ${account?.toLowerCase()} Nonce: ${loginUUID}`

        let signature = ''

        dispatch(setModalSignMessage({ toggle: true }))

        if (!toggle) {
          try {
            signature = await library?.getSigner().signMessage(message)
          } catch {
            if (setLoading) setLoading(false)
            dispatch(setModalSignMessage({ toggle: false }))
            throw new Error()
          }

          if (signature) {
            const dataVerify = {
              walletAddress: account?.toLowerCase(),
              deadline: Math.round((new Date().getTime() + 24 * 60 * 60000) / 1000),
              sig: signature,
              uuid: loginUUID,
            }
            if (setLoading) setLoading(true)
            dispatch(setAddressDeposit({ walletAddress: walletAddressDepoist?.walletAddress, isLoading: true }))
            await authService.verifySignature(dataVerify, async (data) => {
              await authService.login(data?.data?.access_token)
              await authService.generateWallet(
                dataVerify,
                (generateWallet) => {
                  dispatch(setAddressDeposit({ walletAddress: generateWallet?.data?.walletAddress, isLoading: false }))
                  dispatch(setTransactions({ docs: [], isLoading: true }))
                  dispatch(
                    setBalance({
                      balance: {
                        PBC: 0,
                        BNB: 0,
                        USDT: 0,
                      },
                      isLoading: true,
                    })
                  )

                  transactionService.list(
                    account,
                    (data2) => {
                      dispatch(setTransactions({ docs: data2?.docs, isLoading: false }))
                      accountService.getBalance(
                        account,
                        (data3) => {
                          dispatch(
                            setBalance({
                              balance: data3,
                              isLoading: false,
                            })
                          )
                        },
                        () => {
                          dispatch(
                            setBalance({
                              balance: {
                                PBC: 0,
                                BNB: 0,
                                USDT: 0,
                              },
                              isLoading: false,
                            })
                          )
                        }
                      )
                    },
                    () => {
                      dispatch(setTransactions({ docs: [], isLoading: false }))
                      dispatch(
                        setBalance({
                          balance: {
                            PBC: 0,
                            BNB: 0,
                            USDT: 0,
                          },
                          isLoading: false,
                        })
                      )
                    }
                  )

                  if (setLoading) setLoading(false)
                  dispatch(setModalSignMessage({ toggle: false }))
                },
                (e) => {
                  toastError(e?.error || e?.message || 'Wallet creation failed. Please try again later.')
                  dispatch(setAddressDeposit({ walletAddress: '', isLoading: false }))
                  if (setLoading) setLoading(false)
                  dispatch(setModalSignMessage({ toggle: false }))
                }
              )
            })
          } else {
            if (setLoading) setLoading(false)
            dispatch(setModalSignMessage({ toggle: false }))
          }
        } else if (setLoading) setLoading(false)
      }
    },
    [account, dispatch, library, toastError, toggle, walletAddressDepoist?.walletAddress]
  )

  return signMessage
}

export default useSignMessage
