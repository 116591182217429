/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { Button } from 'antd'
import { formatNumber } from 'utils/formatInfoNumbers'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAllTokens } from 'hooks/Tokens'
import { Token } from '@pancakeswap/sdk'
import useToast from 'hooks/useToast'

const WrapPackage = styled.div`
  .package-items {
    position: relative;
    box-shadow: rgb(30, 150, 200) 0px 0px 16.7px 0px;
    border-radius: 1rem;
    border: 4px solid #0063f7;

    &.bronze {
      box-shadow: rgb(199, 124, 48) 0px 0px 2px 0px;
      border: 1px solid #c77c30;
    }

    &.silver {
      box-shadow: rgb(186, 186, 186) 0px 0px 6px 0px;
      border: 2px solid #bababa;
    }

    &.gold {
      box-shadow: rgb(246, 185, 1) 0px 0px 6px 0px;
      border: 3px solid #f6ba01;
    }

    &.platinum {
      box-shadow: rgb(222, 221, 219) 0px 0px 16.7px 0px;
      border: 3px solid #dedddb;
    }

    &.diamond {
      box-shadow: rgb(58, 219, 240) 0px 0px 16.7px 0px;
      border: 3px solid #3adaf0;
    }

    .package-bg-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('/images/packages/bg-item.png');
      background-size: 100% 100%;
      animation: animationBackground 80s infinite linear;
      z-index: 0;
      opacity: 0.5;
      border-radius: 0.5rem;
    }

    .package-content {
      position: relative;
      padding: 15px 10px;
      z-index: 1;

      ${({ theme }) => theme.mediaQueries.lg} {
        padding: 20px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        p {
          font-weight: bold;
        }
      }

      .box-commission-rule {
        flex-wrap: wrap;

        .box-rule {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin-top: 10px;

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            padding: 10px;
            font-weight: normal;

            &:not(:last-child) {
              margin-right: 5px;
            }

            span {
              display: contents;
              color: #2ebd85;
              font-weight: bold;
            }
          }
        }
      }

      .box-action {
        button {
          width: 100%;
        }
      }
    }
  }
`

const Package = ({ dataItem, setModalDeposit, setModalInvestNow }) => {
  const { isDark } = useTheme()
  const balance: any = useSelector<AppState>((state) => state.account.balance)
  const allTokens = useAllTokens()
  const { toastInfo } = useToast()

  const [isDisabled, setDisabled] = useState<boolean>(true)

  const filteredTokens: Token[] = useMemo(() => {
    return Object.values(allTokens)
  }, [allTokens])

  const checkBalance = useCallback(() => {
    const balanceCheck = balance?.balance?.[`${dataItem?.currency}`] / 10 ** 18

    if (balanceCheck >= dataItem?.min_amount / 10 ** 18) {
      setDisabled(false)
    }
  }, [balance?.balance, dataItem?.currency, dataItem?.min_amount])

  useEffect(() => {
    checkBalance()
  }, [checkBalance])

  const HandleInvestNow = useCallback(() => {
    if (isDisabled) {
      toastInfo(`You don't have enough ${dataItem?.currency}. Please deposit!`)
      setModalDeposit({ toggle: true, data: filteredTokens?.find((item) => item?.symbol === dataItem?.currency) })
    } else {
      setModalInvestNow({ toggle: true, data: dataItem })
    }
  }, [dataItem, filteredTokens, isDisabled, setModalDeposit, setModalInvestNow, toastInfo])

  return (
    <WrapPackage className={isDark ? 'theme-dark' : ''}>
      <div className={`package-items ${dataItem?.name?.toLowerCase()}`}>
        <div className="package-bg-item" />

        <div className="package-content">
          <div>
            <p>Name:</p>
            <p>{dataItem?.name}</p>
          </div>
          <div>
            <p>Min amount:</p>
            <p>
              ${formatNumber(dataItem?.min_amount / 10 ** 18, 0, 0)} {dataItem?.currency}
            </p>
          </div>
          <div>
            <p>Max amount:</p>
            <p>
              ${formatNumber(dataItem?.max_amount / 10 ** 18, 0, 0)} {dataItem?.currency}
            </p>
          </div>
          <div>
            <p>Daily Interest Rate:</p>
            <p style={{ color: '#2ebd85' }}>{formatNumber(dataItem?.daily_interest_rate / 100, 1, 1)}%</p>
          </div>
          <div>
            <p>Payout currency:</p>
            <p>{dataItem?.payout_currency}</p>
          </div>
          <div>
            <p>Commission currency:</p>
            <p>{dataItem?.commission_currency}</p>
          </div>

          <div className="box-commission-rule">
            <p>Commission rule:</p>

            <div className="box-rule">
              {dataItem?.commission_rule?.map((item2, index2) => (
                <p>
                  F{index2 + 1}: <span>{item2 / 100}%</span>
                </p>
              ))}
            </div>
          </div>

          <div>
            <p>Maxout:</p>
            <p>300%</p>
          </div>

          <div className="box-action">
            <Button type="primary" onClick={HandleInvestNow}>
              Invest now
            </Button>
          </div>
        </div>
      </div>
    </WrapPackage>
  )
}

export default Package
