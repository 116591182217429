import React, { useState } from 'react'
import styled from 'styled-components'
import { Text } from 'poodlefi-libs-uikit'

interface Props {
  title: string
  value: string
}

const WrapperBox = styled.div``

const StyleButton = styled(Text).attrs({ role: 'button' })`
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 300;
`

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #0063f7;
  color: #fff;
  border-radius: 6px;
  opacity: 0.8;
`

const Chilren = styled.div<{ isTooltipDisplayed: boolean }>`
  opacity: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? '0.3' : '1')};
`

const CopyToClipboard: React.FC<Props> = ({ value, children, ...props }) => {
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  return (
    <WrapperBox>
      <StyleButton
        small
        bold
        onClick={() => {
          if (navigator.clipboard) {
            navigator.clipboard.writeText(value)
            setIsTooltipDisplayed(true)
            setTimeout(() => {
              setIsTooltipDisplayed(false)
            }, 1000)
          }
        }}
        {...props}
      >
        <Chilren isTooltipDisplayed={isTooltipDisplayed}>{children}</Chilren>
        <Tooltip
          style={{
            borderRadius: 0,
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#fff',
          }}
          isTooltipDisplayed={isTooltipDisplayed}
        >
          Copied
        </Tooltip>
      </StyleButton>
    </WrapperBox>
  )
}

export default CopyToClipboard
