/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import styled, { useTheme } from 'styled-components'

interface Props {
  token?: string
}

const StyledNav = styled.div`
  padding: 20px 15px 0 24px;

  .box-select {
    position: relative;
    display: flex;
    algin-items: center;
    height: 56px;
    background: #f2f2f5;
    border: 1px solid #ebebf0;
    border-radius: 12px;
    max-width: 100%;
    width: 150px;
    cursor: no-drop;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      display: block;
      height: 32px;
      width: 32px;
      background-image: url(https://assets.pancakeswap.finance/web/chains/56.png);
      background-size: 100% 100%;
      cursor: no-drop;
    }
  }

  select {
    padding: 12px 16px;
    background: transparent;
    border: none;
    outline: none;
    padding-left: 50px;
    cursor: no-drop;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #1c1c28;
  }

  &.theme-dark {
    .box-select {
      background: rgba(85, 87, 112, 0.9);
      border: 1px solid #8f90a6;
    }

    select {
      color: #ffffff;
    }
  }
`

function Nav({ token = 'BNB' }: Props) {
  const { isDark } = useTheme()

  return (
    <StyledNav className={isDark ? 'theme-dark' : ''}>
      <div className="box-select">
        <select disabled>
          <option value="bsc">{token}</option>
        </select>
      </div>
    </StyledNav>
  )
}

export default Nav
