import React from 'react'
import styled from 'styled-components'
import { CogIcon, Svg } from 'poodlefi-libs-uikit'
import { useTranslation } from 'react-i18next'

const HistoryIcon = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.28 15.54L17 14.33L13.5 12.25V8H12Z"
      fill="#0B509A"
    />
  </Svg>
)

const StyledBlockIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    min-width: unset;
  }
  svg {
    fill: #0063f7 !important;
    path {
      fill: #0063f7 !important;
    }
  }
`

const IconButton = styled.button`
  width: 56px;
  height: 56px;
  background: rgba(242, 242, 245, 0.9);
  border: 1px solid #ebebf0;
  border-radius: 12px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &.theme-dark {
    background: rgba(85, 87, 112, 0.9);
    border: 1px solid #8f90a6;
  }
`

function Setting({ isDark }) {
  const { t } = useTranslation()

  return (
    <StyledBlockIcon>
      <IconButton onClick={() => true} title={t('Settings')} className={isDark ? 'theme-dark' : ''}>
        <CogIcon width="24px" color="#0063F7" />
      </IconButton>
      <IconButton onClick={() => true} title={t('Recent transactions')} className={isDark ? 'theme-dark' : ''}>
        <HistoryIcon />
      </IconButton>
    </StyledBlockIcon>
  )
}

export default Setting
