import axios from 'axios'
import Cookies from 'js-cookie'
import { API_BASE_URL, AuthCache, StatusCode } from 'constants/index'
import { authService } from '../services'

const handleResponse = (response: any) => {
  return response?.data ?? null
}

const handleError = (error: any) => {
  const errRep = error.response

  if ([StatusCode.Unauthorized, StatusCode.Forbidden].includes(errRep?.status)) {
    return authService.logout()
  }

  return errRep
}

const authHeader = () => {
  const token = Cookies.get(AuthCache.AUTH_TOKEN_CACHE)
  return { Authorization: `Bearer ${token}` }
}

export const setBaseHeader = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

async function get(endPoint: string, options?: any) {
  const url = API_BASE_URL + endPoint
  try {
    const response = await axios.get(url, {
      headers: authHeader(),
      ...(options || {}),
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

async function post(endPoint: string, body?: any, options?: any) {
  const url = API_BASE_URL + endPoint
  try {
    const response = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
        ...(options?.headers || {}),
      },
      ...(options || {}),
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

async function put(endPoint: string, body: any) {
  const url = API_BASE_URL + endPoint
  try {
    const response = await axios.put(url, body, {
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

async function patch(endPoint: string, body: any) {
  const url = API_BASE_URL + endPoint
  try {
    const response = await axios.patch(url, body, {
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

async function del(endPoint: string) {
  const url = API_BASE_URL + endPoint
  try {
    const response = await axios.delete(url, {
      headers: authHeader(),
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

async function uploadFile(endPoint: string, body: any) {
  const url = API_BASE_URL + endPoint
  try {
    const response = await axios.post(url, body, {
      headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
    })
    return handleResponse(response)
  } catch (error) {
    return handleError(error)
  }
}

const fetchWrapper = {
  get,
  post,
  put,
  delete: del,
  patch,
  uploadFile,
}

export default fetchWrapper
