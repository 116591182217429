/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import { useState, useCallback } from 'react'
import { Currency, Pair } from '@pancakeswap/sdk'
import { Button, Flex, Text } from 'poodlefi-libs-uikit'
import styled, { useTheme } from 'styled-components'
import { darken } from 'polished'
import DownIcon from 'assets/images/down-icon.svg'
import DownIconBlack from 'assets/images/down-icon-black.svg'
import useI18n from 'hooks/useI18n'
import { useTranslation } from 'react-i18next'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { Input as NumericalInput } from '../NumericalInput'
import { useActiveWeb3React } from '../../hooks'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ selected }) => (selected ? '1.25rem 0.5rem 1.45rem 1rem' : '1.25rem 0.75rem 1.45rem 1rem')};

  > input {
    order: 1;
    text-align: left;
    color: #8f90a6;

    &::placeholder {
      color: #8f90a6;
    }
  }

  > div {
    order: 2;
  }

  > button {
    order: 2;
  }

  &.theme-dark {
    > input {
      color: #8f90a6;

      &::placeholder {
        color: #8f90a6;
      }
    }
  }
`
const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  user-select: none;
  background: #f2f2f5;
  border: 1px solid #ebebf0;
  border-radius: 12px;
  color: #000000;
  height: 56px;
  padding: 12px 16px;

  > span {
    > div {
      font-weight: 500;
      color: #1c1c28 !important;
    }
  }

  &.theme-dark {
    background: #555770;
    border: 1px solid #8f90a6;

    > span {
      > div {
        color: #ffffff !important;
      }
    }
  }
`
const CurrencySelectValid = styled.button<{ selected: boolean }>`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  height: 34px;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 6px;
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  background-color: transparent;

  &.theme-dark {
    color: #ffffff;
  }
`

const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1.2rem 1.45rem 1.2rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.textSubtle)};
  }

  button {
    color: #1d6fea;
  }
`
const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '12px')};
  background-color: rgba(250, 250, 252, 0.9);
  border: none;
  box-shadow: ${({ theme }) => theme.shadows.inset};
  z-index: 1;

  &.theme-dark {
    background-color: rgba(40, 41, 61, 0.9);
  }
`
const Container = styled.div<{ hideInput: boolean }>``

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
}: CurrencyInputPanelProps) {
  const { isDark } = useTheme()
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const translatedLabel = label || t('Input')
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id} className={isDark ? 'theme-dark' : ''}>
      <Container hideInput={hideInput}>
        <InputRow
          className={isDark ? 'theme-dark' : ''}
          style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}
          selected={disableCurrencySelect}
        >
          {!hideInput && (
            <NumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
          )}

          {pair ? (
            <CurrencySelect
              selected={!!currency}
              className={`open-currency-select-button ${isDark ? 'theme-dark' : ''}`}
              onClick={() => {
                if (!disableCurrencySelect) {
                  setModalOpen(true)
                }
              }}
            >
              <Aligner>
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                <Text>
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              </Aligner>
            </CurrencySelect>
          ) : currency ? (
            <CurrencySelect
              selected={!!currency}
              className={`open-currency-select-button ${isDark ? 'theme-dark' : ''}`}
              onClick={() => {
                if (!disableCurrencySelect) {
                  setModalOpen(true)
                }
              }}
            >
              <Aligner>
                <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
                <Text color={isDark ? '#fff' : '#1C1C28'}>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length
                      )}`
                    : currency?.symbol) || t('Select a currency')}
                </Text>
                {!disableCurrencySelect && (
                  <img style={{ marginLeft: '6px' }} src={isDark ? DownIcon : DownIconBlack} width={13} alt="" />
                )}
              </Aligner>
            </CurrencySelect>
          ) : (
            <CurrencySelectValid
              className={`open-currency-select-button ${isDark ? 'theme-dark' : ''}`}
              selected={!!currency}
              onClick={() => {
                if (!disableCurrencySelect) {
                  setModalOpen(true)
                }
              }}
            >
              <Aligner>
                <Text color={isDark ? '#fff' : '#1C1C28'}>{t('Select a currency')}</Text>
                {!disableCurrencySelect && (
                  <img style={{ marginLeft: '6px' }} src={isDark ? DownIcon : DownIconBlack} width={13} alt="" />
                )}
              </Aligner>
            </CurrencySelectValid>
          )}
        </InputRow>

        {!hideInput && (
          <LabelRow>
            <Flex width="100%" justifyContent="end" alignItems="center">
              <Text fontSize="14px" style={{ opacity: '0' }}>
                {translatedLabel}
              </Text>
              {account && (
                <>
                  <Text
                    onClick={onMax}
                    fontSize="14px"
                    style={{ display: 'inline', cursor: 'pointer', color: isDark ? '#ffffff' : '#000000' }}
                  >
                    {!hideBalance && !!currency && selectedCurrencyBalance
                      ? `${t('Balance')}: ${selectedCurrencyBalance?.toSignificant(6)}`
                      : ' -'}
                  </Text>
                  {account && currency && showMaxButton && label !== 'To' && (
                    <Button onClick={onMax} scale="sm" variant="text">
                      {t('Max')}
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </LabelRow>
        )}
      </Container>

      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
