/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import { FieldTimeOutlined } from '@ant-design/icons'
import { Flex } from 'poodlefi-libs-uikit'
import { Table, Tooltip } from 'antd'
import { shortenString } from 'utils'
import { formatAmount } from 'utils/formatInfoNumbers'
import moment from 'moment'
import { packageService } from 'services'

const WrapPackage = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem;
    border-radius: 1.5rem;

    .header-transaction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 16px;
      margin-bottom: 20px;
    }
  }
`

const columns = [
  {
    title: 'Address',
    dataIndex: 'user_account',
    key: 'user_account',
    render: (_, record) => (
      <a href={`https://bscscan.com/address/${record?.user_account?.account_address}`} target="_Blank" rel="noreferrer">
        {shortenString(record?.user_account?.account_address)}
      </a>
    ),
  },
  {
    title: 'Package',
    dataIndex: 'package',
    key: 'package',
    render: (_, record) => <p>{record?.package?.name}</p>,
  },
  {
    title: 'Value',
    dataIndex: 'amount_invest',
    key: 'amount_invest',
    render: (value) => {
      const balance = value / 10 ** 18
      return <p>{balance < 0.01 && balance > -0.01 ? `${balance}`.slice(0, 6) : formatAmount(balance)}</p>
    },
  },
  {
    title: 'Time',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (timeStamp) => (
      <p>
        <Tooltip placement="top" title={moment(timeStamp).format('YYYY-MM-DD HH:mm:ss')}>
          {moment(timeStamp).fromNow()}
        </Tooltip>
      </p>
    ),
  },
]

const Package = () => {
  const { isDark } = useTheme()

  const [listPackage, setListPackage] = useState<any>({
    isLoading: true,
    docs: [],
  })
  const [paramSearch] = useState<any>()

  useEffect(() => {
    packageService.searchAll(paramSearch, (data) => {
      setListPackage({
        ...data?.data,
        isLoading: false,
      })
    })
  }, [paramSearch])

  return (
    <WrapPackage className={isDark ? 'theme-dark' : ''}>
      <div className="container">
        <div className="inner-content">
          <div className="header-transaction">
            <Flex alignItems="center">
              <FieldTimeOutlined style={{ marginRight: '8px', fontSize: '22px' }} /> Recent Package
            </Flex>
          </div>

          <div className="content-transaction">
            <Table
              dataSource={listPackage?.docs}
              columns={columns}
              pagination={{
                total: listPackage?.docs?.length,
                defaultPageSize: 100,
              }}
              scroll={{ x: 400 }}
              loading={listPackage?.isLoading}
            />
          </div>
        </div>
      </div>
    </WrapPackage>
  )
}

export default Package
