import React, { useState } from 'react'
import { Button, ButtonProps } from 'poodlefi-libs-uikit'
import useI18n from 'hooks/useI18n'
import styled from 'styled-components'
import { useActiveWeb3React } from 'hooks'
import ModalConnect from 'components/ModalConnect'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import useGetUsernameBySpaceID from '../../hooks/useGetUsername'

const ButtonConnect = styled.div`
  button {
    height: auto;
    background: #0063f7;
    border-radius: 100px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    color: #fff;
    border-radius: 0.75rem;
  }
`

const UnlockButton: React.FC<ButtonProps> = (props) => {
  const dispatch = useDispatch()
  const TranslateString = useI18n()
  const { account } = useActiveWeb3React()
  const username = useGetUsernameBySpaceID(account?.toString() ?? '')
  const [toggle, setToggle] = useState(false)
  const toggleWalletModal = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const { title } = props

  return (
    <>
      <ButtonConnect>
        {!account ? (
          // <Button onClick={onPresentConnectModal} {...props}>
          <Button onClick={toggleWalletModal} {...props}>
            {title || TranslateString(292, 'Connect wallet')}
          </Button>
        ) : (
          <Button onClick={() => setToggle(true)} {...props}>
            {username ?? `${account.slice(0, 5)}...${account.slice(-5, 60)}`}
          </Button>
        )}
      </ButtonConnect>

      <ModalConnect toggle={toggle} setToggle={setToggle} />
    </>
  )
}

export default UnlockButton
