/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { Col, Row, Skeleton, Empty } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { accountService, packageService } from 'services'
import { useWeb3React } from '@web3-react/core'
import useToast from 'hooks/useToast'
import { setBalance } from 'state/account/actions'
import { useDispatch } from 'react-redux'

import ItemPackage from './components/ItemPackage'
import ModalHistory from './components/ModalHistory'

const WrapPackage = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem 10px;
    border-radius: 1.5rem;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 1.5rem 15px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 1.5rem;
    }

    > p {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }
`

const Package = () => {
  const dispatch = useDispatch()
  const { isDark } = useTheme()
  const { account } = useWeb3React()
  const { toastSuccess, toastError } = useToast()

  const [myPackage, setMyPackage] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [modalHistory, setModalHistory] = useState<{
    toggle: boolean
    data: any
  }>()

  const getMyPackage = useCallback(() => {
    if (account) {
      setLoading(true)

      packageService.myPackage(
        account,
        (data) => {
          setMyPackage(data?.docs)
          setLoading(false)
        },
        () => {
          setLoading(false)
        }
      )
    } else {
      setMyPackage([])
      setLoading(false)
    }
  }, [account])

  useEffect(() => {
    getMyPackage()
  }, [getMyPackage])

  const HandleClaimInterest = useCallback(
    (package_id) => {
      if (package_id) {
        packageService.claimInterest(
          package_id,
          (data) => {
            getMyPackage()
            toastSuccess(data?.message)
            if (account) {
              accountService.getBalance(
                account,
                (data2) => {
                  dispatch(
                    setBalance({
                      balance: data2,
                      isLoading: false,
                    })
                  )
                },
                () => {
                  dispatch(
                    setBalance({
                      balance: {
                        PBC: 0,
                        BNB: 0,
                        USDT: 0,
                      },
                      isLoading: false,
                    })
                  )
                }
              )
            }
          },
          (e) => {
            toastError(e?.error || e?.message || 'Interest addition to principal failed. Please try again later.')
          }
        )
      }
    },
    [account, dispatch, getMyPackage, toastError, toastSuccess]
  )

  return (
    <WrapPackage className={isDark ? 'theme-dark' : ''}>
      <div className="container">
        <div className="inner-content">
          <p>My Investments</p>

          <div className="wrap-packages">
            <Row gutter={[30, 30]}>
              {!loading ? (
                <>
                  {myPackage?.length > 0 ? (
                    <>
                      {myPackage?.map((item) => (
                        <Col key={item.id} span={24} lg={{ span: 12 }}>
                          <ItemPackage
                            dataItem={item}
                            HandleClaimInterest={HandleClaimInterest}
                            setModalHistory={setModalHistory}
                          />
                        </Col>
                      ))}
                    </>
                  ) : (
                    <Col span={24}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Col>
                  )}
                </>
              ) : (
                <>
                  <Col span={24} lg={{ span: 12 }}>
                    <Skeleton active />
                  </Col>
                  <Col span={24} lg={{ span: 12 }}>
                    <Skeleton active />
                  </Col>
                  <Col span={24} lg={{ span: 12 }}>
                    <Skeleton active />
                  </Col>
                  <Col span={24} lg={{ span: 12 }}>
                    <Skeleton active />
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
      </div>

      <ModalHistory modal={modalHistory} setModal={setModalHistory} />
    </WrapPackage>
  )
}

export default Package
