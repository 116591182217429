import React, { useCallback, useEffect, useState } from 'react'
import { Button, InputNumber, Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import CurrencyLogo from 'components/CurrencyLogo'
import { isMobile } from 'react-device-detect'
import { setModalConnect } from 'state/modal/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useCurrencyBalance } from 'state/wallet/hooks'
import Loader from 'components/Loader'
import { accountService, transactionService } from 'services'
import { TOKEN_CONTRACT, TOKEN_CONTRACT_USDT } from 'constants/index'
import { useTokenContract } from 'hooks/useContract'
import { AppState } from 'state'
import { setBalance, setTransactions } from 'state/account/actions'
import { useWeb3React } from '@web3-react/core'
import { getTransactionReceiptMined, toLocaleString } from 'utils'
import useToast from 'hooks/useToast'
import useSignMessage from 'hooks/useSignMessage'
import { ethers } from 'ethers'

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  padding: 30px 0px 40px;
  min-height: 300px;

  @media (min-width: 576px) {
    padding: 0.5rem 14px 1rem;
  }

  .md-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 100%;

    button {
      margin: auto;
      width: 100%;
      max-width: 250px;
    }

    .box-currency {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;

      p {
        font-weight: 600;
      }

      .currency-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.05), 15px 20px 10px rgba(0, 0, 0, 0.05),
          15px 10px 10px rgba(0, 0, 0, 0.05), inset 15px 15px 15px rgba(255, 255, 255, 0.9);
        border-radius: 67% 33% 28% 72% / 59% 52% 48% 41%;
        animation: animationBalloons 5s infinite linear;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;

        ${({ theme }) => theme.mediaQueries.lg} {
          width: 80px;
          height: 80px;
        }
      }

      .arrow {
        transform: rotate(-90deg);
        cursor: pointer;

        span {
          display: block;
          width: 1.5vw;
          height: 1.5vw;
          border-bottom: 5px solid white;
          border-right: 5px solid white;
          transform: rotate(45deg);
          margin: 0;
          animation: animateArrow 1.5s linear infinite;
          opacity: 0;

          &:nth-child(2) {
            animation-delay: 0.1s;
          }
          &:nth-child(3) {
            animation-delay: 0.2s;
          }
          &:nth-child(4) {
            animation-delay: 0.3s;
          }
          &:nth-child(5) {
            animation-delay: 0.4s;
          }
          &:nth-child(6) {
            animation-delay: 0.5s;
          }
          &:nth-child(7) {
            animation-delay: 0.6s;
          }
          &:nth-child(8) {
            animation-delay: 0.7s;
          }
          &:nth-child(9) {
            animation-delay: 0.8s;
          }
        }
      }
    }

    .select-amount {
      margin-bottom: 20px;

      .box-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;

        p {
          font-size: 1rem;
          font-weight: 600;
        }
      }

      .ant-input-number {
        width: 100%;
      }
    }
  }
`

const ModalDeposit = ({ modal, setModal }: { modal: any; setModal: (input: any) => void }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { account, library } = useWeb3React()
  const balance = useCurrencyBalance(account ?? undefined, modal?.data)
  const { toastSuccess, toastError, toastInfo } = useToast()
  const signMessage = useSignMessage()

  const walletAddressDepoist: any = useSelector<AppState>((state) => state.account.walletAddress)
  const transactions: any = useSelector<AppState>((state) => state.account.transactions)

  const [addressDepoistState, setAddressDepositState] = useState<string>('')
  const [inputBalance, setInputBalance] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const tokenContractPBC = useTokenContract(TOKEN_CONTRACT)
  const tokenContractUSDT = useTokenContract(TOKEN_CONTRACT_USDT)

  const connectWallet = useCallback(() => {
    dispatch(setModalConnect({ toggle: true }))
  }, [dispatch])

  useEffect(() => {
    if (walletAddressDepoist?.walletAddress) {
      setAddressDepositState(walletAddressDepoist?.walletAddress as string)
    } else {
      setAddressDepositState('')
    }
  }, [walletAddressDepoist?.walletAddress])

  const handleDeposit = useCallback(
    async (token: string) => {
      if (inputBalance <= 0) {
        toastInfo('The investment amount must be greater than 0.')
        return
      }

      const paramDeposit = {
        _to: addressDepoistState,
        _value: toLocaleString(inputBalance * 10 ** 18),
      }
      setLoading(true)

      if (token === 'BNB') {
        try {
          const recipt = await library?.getSigner().sendTransaction({
            to: paramDeposit?._to,
            value: ethers.utils.parseEther(inputBalance?.toString()),
          })

          const reciptFromHash: any = await getTransactionReceiptMined(recipt?.hash, 500)

          if (reciptFromHash.status === true) {
            toastSuccess('Successful deposit')
            setLoading(false)
            dispatch(setTransactions({ docs: transactions?.docs, isLoading: true }))
            transactionService.list(account as string, (data) => {
              dispatch(setTransactions({ docs: data?.docs, isLoading: false }))
            })
          } else {
            toastError('Deposit failed')
            setLoading(false)
          }
        } catch {
          toastError('Deposit failed')
          setLoading(false)
        }
      } else {
        let tokenContracUse: any

        if (token === 'USDT') {
          tokenContracUse = tokenContractUSDT
        }

        if (token === 'PBC') {
          tokenContracUse = tokenContractPBC
        }

        if (!tokenContracUse) {
          toastError('Contract has failed. Please try again later')
          setLoading(false)
          return
        }

        try {
          await tokenContracUse.transfer(paramDeposit?._to, paramDeposit?._value).then(async (result) => {
            const recipt: any = await getTransactionReceiptMined(result.hash, 500)

            if (recipt.status === true) {
              toastSuccess('Successful deposit')
              setLoading(false)
              dispatch(setTransactions({ docs: transactions?.docs, isLoading: true }))
              transactionService.list(account as string, (data) => {
                dispatch(setTransactions({ docs: data?.docs, isLoading: false }))

                accountService.getBalance(account as string, (data2) => {
                  dispatch(
                    setBalance({
                      balance: data2,
                      isLoading: false,
                    })
                  )
                })
              })
            } else {
              toastError('Deposit failed')
              setLoading(false)
            }
          })
        } catch {
          toastError('Deposit failed')
          setLoading(false)
        }
      }
    },
    [
      account,
      addressDepoistState,
      dispatch,
      inputBalance,
      library,
      toastError,
      toastInfo,
      toastSuccess,
      tokenContractPBC,
      tokenContractUSDT,
      transactions?.docs,
    ]
  )

  return (
    <Modal
      key="modal-connect"
      visible={modal?.toggle}
      centered
      width={640}
      title={`${t('Deposit')} ${modal?.data?.symbol}`}
      footer={null}
      onCancel={() => {
        if (!loading) {
          setModal({
            toogle: false,
            data: null,
          })
          setInputBalance(0)
        }
      }}
      closeIcon={<img src="/images/icons/close-white.png?v=1.1" alt="" />}
    >
      <ModalContent>
        <div className="md-content">
          {addressDepoistState ? (
            <>
              <div className="box-currency">
                <div>
                  <p style={{ opacity: 0 }}>From BSC</p>
                  <div className="currency-item">
                    <CurrencyLogo currency={modal?.data} size={isMobile ? '30px' : '50px'} />
                  </div>
                  <p style={{ textAlign: 'center' }}>From BSC</p>
                </div>

                <div className="arrow">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>

                <div>
                  <p style={{ opacity: 0 }}>To Public Wallet</p>
                  <div className="currency-item">
                    <img src="/images/logo.png" width={isMobile ? '30px' : '50px'} alt="" />
                  </div>
                  <p style={{ textAlign: 'center' }}>To Public Wallet</p>
                </div>
              </div>

              <div className="select-amount">
                <div className="box-label">
                  <span>Balance: {balance ? balance.toSignificant(4) : account ? <Loader /> : 0}</span>
                </div>
                <InputNumber
                  onChange={(e) => setInputBalance(+e)}
                  value={+inputBalance}
                  disabled={!account && !balance}
                  max={balance ? +`${balance.toSignificant(4)}` : 0}
                  min={0}
                />
              </div>

              {account ? (
                <Button type="primary" onClick={() => handleDeposit(modal?.data?.symbol)} loading={loading}>
                  Confirm
                </Button>
              ) : (
                <Button type="primary" onClick={connectWallet}>
                  Connect Wallet
                </Button>
              )}
            </>
          ) : (
            <>
              {account ? (
                <Button
                  type="primary"
                  onClick={() => signMessage(setLoading)}
                  loading={loading || walletAddressDepoist?.isLoading}
                >
                  {loading && <Spin indicator={<LoadingOutlined spin />} size="small" />}
                  Get Address Deposit
                </Button>
              ) : (
                <Button type="primary" onClick={connectWallet}>
                  Connect Wallet
                </Button>
              )}
            </>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalDeposit)
