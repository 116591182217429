/* eslint-disable consistent-return */
import { stringify } from 'helpers'
import fetchWrapper from 'helpers/fetch-wrapper'

const apiBaseUrl = `/account`

const connect = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/connect`, body).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const getBalance = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/balance/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe(data?.data)
  })
}

const getWallet = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/get-wallet/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data?.data)
      return data?.data
    }
    if (cbe) cbe(data?.data)
  })
}

const withdraw = async (body: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.post(`${apiBaseUrl}/withdraw`, body).then((data: any) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const checkWithdraw = async (wallet_address: string, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  return fetchWrapper.get(`${apiBaseUrl}/no-withdraw/${wallet_address}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const search = async (payload: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  const qStr = payload ? stringify(payload) : ''

  return fetchWrapper.get(`${apiBaseUrl}/search?${qStr}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

const searchPagination = async (payload: any, cbs?: (res: any) => void, cbe?: (res?: any) => void) => {
  const qStr = payload ? stringify(payload) : ''

  return fetchWrapper.get(`${apiBaseUrl}/search-pagination?${qStr}`).then((data) => {
    if ((data?.status === 200 || data?.status === 201) && data?.data) {
      if (cbs) cbs(data)
      return data
    }
    if (cbe) cbe(data?.data)
  })
}

export default { connect, getBalance, getWallet, withdraw, checkWithdraw, search, searchPagination }
