import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface ModalState {
  modalConnect: actions.ModalType
  modalSignMessage: actions.ModalType
}

const initialState: ModalState = {
  modalConnect: { toggle: false, dataModal: null },
  modalSignMessage: { toggle: false },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setModalConnect, (state, { payload }) => {
      state.modalConnect = payload
    })
    .addCase(actions.setModalSignMessage, (state, { payload }) => {
      state.modalSignMessage = payload
    })
)
