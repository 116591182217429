/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { Col, Row, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { formatAmount } from 'utils/formatInfoNumbers'
import { transactionService } from 'services'

const WrapAssets = styled.div`
  .inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(9 5 36 / 1);
    padding: 2.25rem 2rem;
    border-radius: 1.5rem;
    height: 100%;

    .ant-row {
      width: 100%;
    }

    .wrap-asset-item {
      ${({ theme }) => theme.mediaQueries.lg} {
        display: flex;
        justify-content: center;
      }

      .asset-item {
        p {
          font-size: 1.8rem;
          font-weight: 600;
          color: #d3d1ff;

          ${({ theme }) => theme.mediaQueries.lg} {
            font-size: 3rem;
          }

          &:nth-child(1) {
            font-size: 1rem;
            margin-bottom: 8px;
            color: #fff;

            ${({ theme }) => theme.mediaQueries.lg} {
              font-size: 1.25rem;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }

  .inner-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    background-color: rgb(9 5 36 / 1);
    padding: 20px 15px;
    border-radius: 1.5rem;

    > p {
      font-size: 1rem;
      font-weight: 600;
      color: #fff;
      width: 100%;
      margin-bottom: 16px;
    }

    .box-qr {
      display: flex;
      justify-content: center;
      align-item: center;

      .content-qr {
        height: 90px;
        padding: 10px;
        background: #fff;

        svg {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
`

const Assets = () => {
  const { isDark } = useTheme()

  const [totalInfo, setTotalInfo] = useState({
    data: {
      PBC: 0,
      BNB: 0,
      USDT: 0,
    },
    isLoading: true,
  })

  useEffect(() => {
    transactionService.totalBalance((data) => {
      setTotalInfo({
        data: data?.data,
        isLoading: false,
      })
    })
  }, [])

  return (
    <WrapAssets className={isDark ? 'theme-dark' : ''}>
      <div className="container">
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <div className="inner-content">
              <Row gutter={[30, 30]}>
                <Col span={24} lg={{ span: 8 }}>
                  <div className="wrap-asset-item">
                    <div className="asset-item">
                      <p>Total Balance PBC</p>
                      {totalInfo.isLoading ? (
                        <Skeleton.Button active size="large" />
                      ) : (
                        <p>{formatAmount(totalInfo?.data?.PBC / 10 ** 18)}</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={24} lg={{ span: 8 }}>
                  <div className="wrap-asset-item">
                    <div className="asset-item">
                      <p>Total Balance BNB</p>
                      {totalInfo.isLoading ? (
                        <Skeleton.Button active size="large" />
                      ) : (
                        <p>{formatAmount(totalInfo?.data?.BNB / 10 ** 18)}</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={24} lg={{ span: 8 }}>
                  <div className="wrap-asset-item">
                    <div className="asset-item">
                      <p>Total Balance USDT</p>
                      {totalInfo.isLoading ? (
                        <Skeleton.Button active size="large" />
                      ) : (
                        <p>{formatAmount(totalInfo?.data?.USDT / 10 ** 18)}</p>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </WrapAssets>
  )
}

export default Assets
