import React, { useMemo } from 'react'
import { Modal, Table, Tooltip } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useTheme from 'hooks/useTheme'
import { formatNumber } from 'helpers'
import moment from 'moment'

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  padding: 30px 0px 40px;
  min-height: 200px;

  @media (min-width: 576px) {
    padding: 0.5rem 14px 1rem;
  }

  &.theme-dark {
    * {
      color: #ffffff;
    }
  }

  .md-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 100%;
  }
`

const ModalHistory = ({ modal, setModal }: { modal: any; setModal: (input: any) => void }) => {
  const { t } = useTranslation()
  const { isDark } = useTheme()

  const columns = useMemo(
    () => [
      {
        title: 'Package',
        dataIndex: 'before_claim_amount',
        key: 'before_claim_amount',
        render: () => <p>{modal?.data?.package?.name}</p>,
      },
      {
        title: 'Profit',
        dataIndex: 'claimed_amount',
        key: 'claimed_amount',
        render: (amount) => (
          <p>
            {formatNumber(amount / 10 ** 18)} {modal?.data?.package?.payout_currency}
          </p>
        ),
      },
      {
        title: 'From date',
        dataIndex: 'interest_start',
        key: 'interest_start',
        render: (timeStamp) => <p>{moment.unix(timeStamp).format('YYYY-MM-DD HH:mm:ss')}</p>,
      },
      {
        title: 'To date',
        dataIndex: 'interest_end',
        key: 'interest_end',
        render: (timeStamp) => <p>{moment.unix(timeStamp).format('YYYY-MM-DD HH:mm:ss')}</p>,
      },
      {
        title: 'Claimed at',
        dataIndex: 'claimed_at',
        key: 'claimed_at',
        render: (timeStamp) => (
          <p>
            <Tooltip placement="top" title={moment.unix(timeStamp).format('YYYY-MM-DD HH:mm:ss')}>
              {moment.unix(timeStamp).fromNow()}
            </Tooltip>
          </p>
        ),
      },
    ],
    [modal?.data?.package?.name, modal?.data?.package?.payout_currency]
  )

  return (
    <Modal
      key="modal-connect"
      visible={modal?.toggle}
      centered
      width={1000}
      title={`${t('History claim')}`}
      footer={null}
      onCancel={() => {
        setModal({
          toogle: false,
          data: null,
        })
      }}
      closeIcon={<img src="/images/icons/close-white.png?v=1.1" alt="" />}
    >
      <ModalContent className={isDark ? 'theme-dark' : ''}>
        <div className="md-content">
          <Table
            dataSource={modal?.data?.interest_claim_history?.sort((a, b) => b.claimed_at - a.claimed_at)}
            columns={columns}
            pagination={{
              total: modal?.data?.interest_claim_history?.length,
              defaultPageSize: 5,
            }}
            scroll={{ x: 924 }}
          />
        </div>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalHistory)
