/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { Col, Empty, Row, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import ModalDeposit from 'components/ModalDeposit'
import ModalInvestNow from 'components/ModalInvestNow'
import { packageService } from 'services'

import ItemPackage from './components/ItemPackage'

const WrapPackage = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem 10px;
    border-radius: 1.5rem;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 1.5rem 15px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      padding: 1.5rem;
    }

    > p {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }
`

const Package = () => {
  const { isDark } = useTheme()

  const [listPackage, setListPackage] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [modalDeposit, setModalDeposit] = useState<any>({
    toggle: false,
    data: null,
  })
  const [modalInvestNow, setModalInvestNow] = useState<any>({
    toggle: false,
    data: null,
  })

  useEffect(() => {
    setLoading(true)
    packageService.search(
      (data) => {
        setListPackage(data?.docs)
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    )
  }, [])

  return (
    <>
      <WrapPackage className={isDark ? 'theme-dark' : ''}>
        <div className="container">
          <div className="inner-content">
            <p>Packages</p>

            <div className="wrap-packages">
              <Row gutter={[30, 30]}>
                {!loading ? (
                  <>
                    <>
                      {listPackage?.length > 0 ? (
                        <>
                          {listPackage?.map((item) => (
                            <Col key={item.id} span={24} lg={{ span: 12 }}>
                              <ItemPackage
                                dataItem={item}
                                setModalDeposit={setModalDeposit}
                                setModalInvestNow={setModalInvestNow}
                              />
                            </Col>
                          ))}
                        </>
                      ) : (
                        <Col span={24}>
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Col>
                      )}
                    </>
                  </>
                ) : (
                  <>
                    <Col span={24} lg={{ span: 12 }}>
                      <Skeleton active />
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                      <Skeleton active />
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                      <Skeleton active />
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                      <Skeleton active />
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </div>
        </div>
      </WrapPackage>
      <ModalDeposit modal={modalDeposit} setModal={setModalDeposit} />
      <ModalInvestNow modal={modalInvestNow} setModal={setModalInvestNow} />
    </>
  )
}

export default Package
