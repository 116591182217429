/* eslint-disable react/react-in-jsx-scope */
import styled from 'styled-components'
import { Currency, Token } from '@pancakeswap/sdk'
import useTheme from 'hooks/useTheme'
import { useAllTokens } from 'hooks/Tokens'
import { useEffect, useMemo, useState } from 'react'
import ModalDeposit from 'components/ModalDeposit'
import ModalWithdraw from 'components/ModalWithdraw'
import { accountService } from 'services'
import { useActiveWeb3React } from 'hooks'

import ItemAsset from './ItemAsset'

const WrapAssets = styled.div`
  .inner-content {
    background: rgb(32 27 67 / 1);
    padding: 1.5rem;
    border-radius: 1.5rem;

    > p {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .wrap-table {
      overflow: auto;

      .content-table {
        min-width: max-content;

        > table {
          width: 100%;

          th,
          td {
            padding: 15px 10px;
            text-align: right;

            &:nth-child(1) {
              text-align: left;
            }
          }

          td {
            > div {
              max-width: unset;
            }

            svg {
              path {
                fill: #906ff2;
              }
            }
          }
        }
      }
    }

    .wrap-logo {
      display: flex;

      .wrap-info {
        margin-left: 15px;

        p {
          font-size: 0.875rem;

          &:nth-child(1) {
            font-size: 1rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  button {
    &:disabled {
      color: rgba(255, 255, 255, 0.4);
    }
  }
`

const Assets = () => {
  const { account } = useActiveWeb3React()

  const { isDark } = useTheme()
  const allTokens = useAllTokens()

  const [modalDeposit, setModalDeposit] = useState<any>({
    toggle: false,
    data: null,
  })
  const [modalWithdraw, setModalWithdraw] = useState<any>({
    toggle: false,
    data: null,
  })
  const [isWithdraw, setIsWithdraw] = useState<boolean>(false)

  const filteredTokens: Token[] = useMemo(() => {
    return Object.values(allTokens)
  }, [allTokens])

  useEffect(() => {
    if (account) {
      accountService.checkWithdraw(account, (data) => {
        setIsWithdraw(!data?.data?.withdraw)
      })
    }
  }, [account])

  return (
    <>
      <WrapAssets className={isDark ? 'theme-dark' : ''}>
        <div className="container">
          <div className="inner-content">
            <p>All Assets</p>

            <div className="wrap-table">
              <div className="content-table">
                <table>
                  <tr>
                    <th>Asset</th>
                    <th>Balance</th>
                    <th>Deposit</th>
                    <th>Withdraw</th>
                  </tr>

                  <tr>
                    <ItemAsset
                      currency={Currency.ETHER}
                      setModalDeposit={setModalDeposit}
                      setModalWithdraw={setModalWithdraw}
                      isWithdraw={isWithdraw}
                    />
                  </tr>

                  {filteredTokens?.map((item) => (
                    <tr key={item?.address}>
                      <ItemAsset
                        currency={item}
                        setModalDeposit={setModalDeposit}
                        setModalWithdraw={setModalWithdraw}
                        isWithdraw={isWithdraw}
                      />
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </WrapAssets>

      <ModalDeposit modal={modalDeposit} setModal={setModalDeposit} />
      <ModalWithdraw modal={modalWithdraw} setModal={setModalWithdraw} />
    </>
  )
}

export default Assets
