/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/named */
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'

const SID = require('@siddomains/sidjs').default
const SIDfunctions = require('@siddomains/sidjs')

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

const useGetUsernameBySpaceID = (address: string) => {
  const [data, setData] = useState<string>('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(NETWORK_URL)
        const chainId = process.env.REACT_APP_CHAIN_ID
        const sid = new SID({ provider, sidAddress: SIDfunctions.getSidAddress(chainId) })

        const fetchUsername = await sid.getName(address)
        setData(fetchUsername?.name ?? null)
      } catch (error) {
        console.error('useGetUsernameInSpaceId', error)
      }
    }

    fetchData()
  }, [address, setData])

  return data
}

export default useGetUsernameBySpaceID
