import { createAction } from '@reduxjs/toolkit'

export const setAddressDeposit: any = createAction<{ walletAddress: string; isLoading: boolean }>('account/setAddressDeposit')
export const setTransactions: any = createAction<{ docs: any[]; isLoading: boolean }>('account/setTransactions')
export const setBalance: any = createAction<{
  balance: {
    PBC: number
    BNB: number
    USDT: number
  }
  isLoading: boolean
}>('account/setBalance')
