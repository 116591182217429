import { NoBscProviderError } from '@binance-chain/bsc-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { ConnectorNames, connecterId, connectorsByName } from 'connectors/connectorsV2'
import useToast from 'hooks/useToast'
import { useCallback } from 'react'

const useAuthV2 = () => {
  const { activate, deactivate } = useWeb3React()
  const { toastError } = useToast()

  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]
    if (connector) {
      activate(connector, async (error: Error) => {
        window.localStorage.removeItem(connecterId)
        if (error instanceof UnsupportedChainIdError) {
          toastError('Unsupported Chain Id')
        } else if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
          toastError('Provider Error, No provider was found')
        } else if (error instanceof UserRejectedRequestErrorInjected || error instanceof UserRejectedRequestErrorWalletConnect) {
          if (connector instanceof WalletConnectConnector) {
            const walletConnector = connector as WalletConnectConnector
            walletConnector.walletConnectProvider = undefined
          }
          toastError('Authorization Error, Please authorize to access your account')
        } else {
          toastError(error.name + error.message)
        }
      })
    } else {
      toastError("Can't find connector, The connector config is wrong")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(() => {
    window.localStorage.removeItem(connecterId)
    deactivate()
  }, [deactivate])

  return { login, logout }
}

export default useAuthV2
