/* eslint-disable react/react-in-jsx-scope */
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'

const WrapperFooter = styled.div`
  width: calc(100vw - 250px);
  margin-left: 250px;

  &.theme-dark {
    background-color: rgb(20 15 52 / 1);

    * {
      color: #fff;
    }
  }
`

export default function Footer() {
  const { isDark } = useTheme()

  return <WrapperFooter className={isDark ? 'theme-dark' : ''} />
}
